import axiosClient from "./axiosClient";

export const getListRole = () => {
  const url = "/p/role";
  return axiosClient.get(url);
};

export const permissionsRole = () => {
  const url = "/p/role/permissions";
  return axiosClient.get(url);
};

export const createNewRole = (params) => {
  const url = "/p/role";
  return axiosClient.post(url, params);
};

export const deleteRoleById = (id) => {
  const url = `/p/role/${id}`;
  return axiosClient.delete(url);
};

export const updateRoleById = (id, params) => {
  const url = `/p/role/${id}`;
  return axiosClient.put(url, params);
};

export const getlistUserRoleById = (id) => {
  const url = `/p/role/${id}/users`;
  return axiosClient.get(url);
};

export const getRoleById = (id) => {
  const url = `/p/role/${id}`;
  return axiosClient.get(url);
};

export const assignRoles = (id, params) => {
  const url = `/p/user/${id}/assignRoles`;
  return axiosClient.put(url, params);
};
