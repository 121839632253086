import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, List, Divider as MuiDivider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";

import { SidebarItemsType } from "../../types/sidebar";
import SidebarNavSection from "./SidebarNavSection";

import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import HistoryIcon from "@mui/icons-material/History";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getListChat } from "../../api/chatApi";
import { updateChatList } from "../../redux/slices/chatList";
import "../../vendor/perfect-scrollbar.css";
import "./Sidebar.scss";

const baseScrollbar = (props: any) => css`
  // background-color: ${props.theme.sidebar.background};
  background-color: #fafaf9;
  // border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
  height: 100vh;
  overflow-y: hidden;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const WrapSideBar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};
`;

type SidebarNavProps = {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
};

const Title = styled(Typography)`
  color: #000;
  font-size: 12px;

  opacity: 0.8;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WrapListChat = styled.div`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: 5px;
  overflow: auto;
  height: calc(100vh - 330px);
  direction: ltr;
  ::-webkit-scrollbar {
    background: #fff;
    border: 1px solid transparent;
    height: 4px;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #376fd0;
    border-radius: 10px;
  }
`;
const ItemChat = styled.div`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  display: flex;
  gap: 5px;
  margin-bottom: 8px;
`;

const WrapTitle = styled.div`
  color: ${(props) => props.theme};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 5px;
  padding-top: 5px;
`;

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const theme: any = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType;
  const navigate = useNavigate();
  const chatList = useSelector((state: any) => state.chatList.list);
  const dispatch = useDispatch();

  const idActive = localStorage.getItem("current-chat-id");

  const handleGetListChat = async () => {
    try {
      const { data } = await getListChat();
      const action = updateChatList(data);
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateToChatId = (id: string, e: any) => {
    navigate(`/?id=${id}`);
    localStorage.setItem("current-chat-id", id);
  };

  const handleNavigateNewChat = () => {
    localStorage.removeItem("current-chat-id");
    navigate(`/`);
  };

  useEffect(() => {
    handleGetListChat();
  }, []);

  return (
    <WrapSideBar>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
              />
            ))}
        </Items>
      </List>
      <div style={{ padding: 5 }}>
        <Button
          sx={{
            color: "#000",
            border: "1px dashed #000",
            width: "100%",
            marginBottom: 2,
            fontSize: 16,
          }}
          onClick={handleNavigateNewChat}
        >
          New Chat
        </Button>
      </div>
      {/* <WrapTitle>
        <HistoryIcon sx={{ color: "#000" }} />
        <Title>History</Title>
      </WrapTitle> */}
      <ScrollbarComponent>
        <WrapListChat>
          {chatList?.map((items: any) => (
            <ItemChat
              key={items.id}
              onClick={(e) => handleNavigateToChatId(items.id, e)}
              className={`chat-item-menu ${
                idActive && idActive === items.id ? "active" : ""
              }`}
            >
              <ChatOutlinedIcon sx={{ color: "#000" }} />
              <Title>
                {items.title[0].toUpperCase() + items.title.slice(1)}
              </Title>
            </ItemChat>
          ))}
        </WrapListChat>
      </ScrollbarComponent>
    </WrapSideBar>
  );
};

export default SidebarNav;
