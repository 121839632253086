import React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Avatar,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import SidebarFooter from "../sidebar/SidebarFooter";
import UpdatePassWordModal from "./components/UpdatePassWordModal";
import { useModalProps } from "../../hooks/useModalProps";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/user";
import { Flex } from "antd";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const FooterText = styled.div`
  color: ${(props) => props.theme.sidebar.footer.color};
  display: flex;
  align-items: center;
  height: 100%;
`;

function NavbarUserDropdown() {
  const accessToken = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.user);
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const { signOut } = useAuth();
  const updatePassWordModal = useModalProps();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleOpenModalUpdatePassWord = () => {
    updatePassWordModal.onOpen();
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
        }}
      >
        {accessToken && user?.fullName ? (
          <Flex align="center" gap={5} onClick={toggleMenu}>
            {user?.fullName}
            <Avatar>{user?.fullName[0]}</Avatar>
          </Flex>
        ) : (
          <Flex align="center" gap={5} onClick={toggleMenu}>
            <Avatar>D</Avatar>
          </Flex>
        )}
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        <MenuItem onClick={handleOpenModalUpdatePassWord}>
          Update Password
        </MenuItem>
      </Menu>
      <UpdatePassWordModal modalProps={updatePassWordModal} />
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
