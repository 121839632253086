import { Form, Input, InputNumber, Modal } from "antd";
import { toast } from "react-toastify";
import { updateUserInfo } from "../../../../api/userApi";
import { useEffect } from "react";

const EditUserModal = ({ editUserModal, handleEditUser }: any) => {
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    const params = {
      fullName: values.fullName,
      email: values.email,
      password: values.password,
      status: "Active",
    };

    const res: any = await handleEditUser(params, editUserModal.data?.id);
    if (res === true) {
      editUserModal.onCancel();
      form.resetFields();
    }
  };

  useEffect(() => {
    form.setFieldValue("fullName", editUserModal.data?.fullName);
    form.setFieldValue("email", editUserModal.data?.email);
    return () => {
      form.resetFields();
    };
  }, [editUserModal.visible]);

  return (
    <Modal
      open={editUserModal.visible}
      onCancel={editUserModal.onCancel}
      title="Edit user"
      onOk={() => {
        form.submit();
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="fullName"
          label="Full name:"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email:"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password:"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
