import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { registerUser } from "../../api/userApi";
import { toast } from "react-toastify";
import { Flex } from "antd";
import GoogleButton from "react-google-button";
import { REACT_APP_BASE_URL } from "../../constants";
import { loginSocial } from "../../redux/slices/user";
import { useDispatch } from "react-redux";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [slotCode, setSlotCode] = React.useState("");

  const handleLoginGoogle = async () => {
    try {
      const params: any = {
        callback: `${window.location.protocol}//${window.location.host}/auth/sign-in`,
        slotCode: slotCode,
      };
      const url = `${REACT_APP_BASE_URL}/o/authorize/google?callback=${params.callback}&slotCode=${params.slotCode}`;
      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const code: any = searchParams.get("slotCode");
    setSlotCode(code);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          fullName: "",
          slotCode: slotCode,
          email: "",
          password: "",
          confirmPass: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().max(255).required("Name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .min(12, "Must be at least 12 characters")
            .max(255)
            .required("Required"),
          confirmPass: Yup.string().oneOf(
            // @ts-ignore
            [Yup.ref("password"), null],
            "Passwords must match"
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (slotCode === "") {
              setErrors({ slotCode: "Slot code is required" });
              setSubmitting(false);
              return;
            }
            const params = {
              fullName: values.fullName,
              email: values.email,
              password: values.password,
              slotCode: values.slotCode === "" ? slotCode : values.slotCode,
              confirmPass: values.confirmPass,
            };

            const res: any = await registerUser(params);
            if (res.code === 200) {
              toast.success("Register success");
              navigate("/auth/sign-in");
            }
          } catch (error: any) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="text"
              name="fullName"
              label="Full Name"
              value={values.fullName}
              error={Boolean(touched.fullName && errors.fullName)}
              fullWidth
              helperText={touched.fullName && errors.fullName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="email"
              name="email"
              label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="confirmPass"
              label="Confirm password"
              value={values.confirmPass}
              error={Boolean(touched.confirmPass && errors.confirmPass)}
              fullWidth
              helperText={touched.confirmPass && errors.confirmPass}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="slotCode"
              label="Slot Code"
              value={slotCode}
              error={Boolean(touched.slotCode && errors.slotCode)}
              fullWidth
              helperText={touched.slotCode && errors.slotCode}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Sign up
            </Button>
          </form>
        )}
      </Formik>
      <Flex align="center" justify="center" style={{ marginTop: 20 }}>
        <GoogleButton onClick={handleLoginGoogle} />
      </Flex>
    </>
  );
}

export default SignUp;
