// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards

// Auth components
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";

// Protected routes
import ChatPage from "./pages/dashboards/ChatPage";
import DocumentsPage from "./pages/dashboards/DocumentsPage";
import MediaPage from "./pages/dashboards/MediaPage";
import UsersPage from "./pages/dashboards/UsersPage";
import Setting from "./pages/dashboards/Setting";
import Trash from "./pages/dashboards/Trash";
import SlotPage from "./pages/dashboards/SlotPage";
import Policy from "./pages/dashboards/Policy";
import TermsOfService from "./pages/dashboards/TermOfservices/index";

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <ChatPage />,
        key: "chat-ai",
      },
      {
        path: "document-list",
        element: <DocumentsPage />,
        key: "documents",
      },
      {
        path: "trash-list",
        element: <Trash />,
        key: "trash",
      },
      {
        path: "media-list",
        element: <MediaPage />,
        key: "media",
      },
      {
        path: "user-list",
        element: <UsersPage />,
        key: "users",
      },
      {
        path: "slot-list",
        element: <SlotPage />,
        key: "slots",
      },
      {
        path: "setting",
        element: <Setting />,
        key: "setting",
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      // {
      //   path: "reset-password",
      //   element: <ResetPassword />,
      // },
      {
        path: "404",
        element: <Page404 />,
      },
      // {
      //   path: "500",
      //   element: <Page500 />,
      // },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
      {
        path: "policy",
        element: <Policy />,
        key: "setting",
      },
      {
        path: "termOfservices",
        element: <TermsOfService />,
        key: "setting",
      },
    ],
  },
];

export default routes;
