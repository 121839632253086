export const MEDIA_TYPE: any = {
  TEXT: 1,
  VIDEO: 2,
  AUDIO: 3,
  IMAGE: 4,
  LINK: 5,
};

export const MEDIA_SUB_TYPE: any = {
  PDF: 1,
  MSWORDS: 2,
  TXTFILE: 3,
  CSVFILE: 4,
  VIDEO: 5,
  YOUTUBE: 6,
  AUDIO: 7,
  IMAGE: 8,
  OTHERLINK: 99,
};

export const MEDIA_SUB_TYPE_NAME: any = {
  1: "application/pdf",
  2: "application/msword",
  3: "text/plain",
  4: "text/csv",
  5: "video/mp4", // MIME type phổ biến cho video. Bạn có thể thay đổi thành MIME type cụ thể nếu biết rõ loại video.
  6: "video/youtube", // YouTube không có MIME type cụ thể, nhưng bạn có thể sử dụng một giá trị đại diện như thế này.
  7: "audio/mpeg", // MIME type phổ biến cho audio. Bạn có thể thay đổi thành MIME type cụ thể nếu biết rõ loại audio.
  8: "image/jpeg", // MIME type phổ biến cho hình ảnh. Bạn có thể thay đổi thành MIME type cụ thể nếu biết rõ loại hình ảnh.
  99: "application/octet-stream", // MIME type chung cho các loại tập tin khác.
};
