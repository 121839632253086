import { DatePicker, Form, Input, InputNumber, Modal } from "antd";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";

const QuickUpdateModal = ({ qiickUpdateModal }: any) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    // if (!handleCreateSlot) return;
    const params = {
      userSlotIds: [],
      expiredAt: moment(new Date(values.expiredAt)).format("YYYY-MM-DD"),
    };
    console.log(params);

    // const res = await handleCreateSlot(params);
    // if (res === true) {
    //   toast.success("Create slot success");
    // } else {
    //   toast.error("Create slot failed");
    // }
    // form.resetFields();
    // qiickUpdateModal.onCancel();
  };
  return (
    <Modal
      open={qiickUpdateModal.visible}
      onCancel={qiickUpdateModal.onCancel}
      onOk={form.submit}
      className="quick-update-expiration-modal"
      title={"Update Expiration"}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="expiredAt"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <DatePicker
            popupClassName="quick-update-expiration-modal__date-picker"
            style={{ width: "100%" }}
            disabledDate={(current: any) =>
              current && current.valueOf() < Date.now()
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QuickUpdateModal;
