import { Avatar, Flex, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { getAllUser } from "../../../../api/userApi";
import { UserOutlined } from "@ant-design/icons";

const AddUserToTeamModal = ({
  addUserToTeamModal,
  handleAddUserToTeam,
}: any) => {
  const [form] = Form.useForm();
  const [listUser, setListUser] = useState([]);

  const handleGetAllUser = async (list: any) => {
    try {
      const res: any = await getAllUser();
      const dataFormat = res?.data?.map((user: any) => {
        return {
          value: user?.id,
          label: user?.fullName,
          email: user?.email,
          disabled: list.includes(user?.id),
        };
      });
      setListUser(dataFormat);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values: any) => {
    let userIDs = values.userIDs;
    if (addUserToTeamModal?.data?.style === "remove") {
      userIDs = listUser
        .filter((user: any) => !userIDs.includes(user.value))
        .map((user: any) => user.value);
    }
    handleAddUserToTeam(
      userIDs,
      addUserToTeamModal?.data?.id,
      addUserToTeamModal?.data?.style
    );
    form.resetFields();
  };

  useEffect(() => {
    if (addUserToTeamModal.visible) {
      const list = addUserToTeamModal?.data?.users?.map(
        (user: any) => user?.id
      );
      if (addUserToTeamModal?.data?.style === "add") {
        handleGetAllUser(list);
      } else {
        const listOg = addUserToTeamModal?.data?.users?.map((user: any) => {
          return {
            value: user?.id,
            label: user?.fullName,
            email: user?.email,
          };
        });
        setListUser(listOg);
      }
      form.setFieldValue("userIDs", list);
    }
  }, [addUserToTeamModal?.data, addUserToTeamModal.visible]);

  return (
    <Modal
      open={addUserToTeamModal.visible}
      onCancel={() => {
        addUserToTeamModal.onCancel();
      }}
      title={
        addUserToTeamModal?.data?.style === "add" ? "Add User" : "Remove User"
      }
      onOk={form.submit}
      className="user-modal"
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="userIDs">
          <Select
            mode="multiple"
            placeholder="Search to Select"
            optionFilterProp="children"
            maxTagCount="responsive"
            optionRender={({ data }: any, info: any) => {
              return (
                <>
                  <Avatar size={40}>{data.label[0]}</Avatar> {data.label}
                </>
              );
            }}
            filterOption={(input, option: any) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            // filterSort={(optionA, optionB: any) =>
            //   (optionA?.label ?? "")
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? "").toLowerCase())
            // }
            options={listUser}
            tagRender={() => <></>}
          />
        </Form.Item>
        <Flex vertical gap={5}>
          {addUserToTeamModal?.data?.users?.map((user: any) => (
            <div key={user.id}>
              <Flex align="center" gap={5}>
                <Avatar size={40}>
                  {user.fullName[0] ? user.fullName[0] : <UserOutlined />}
                </Avatar>
                <Flex vertical>
                  {user.fullName}
                  <span style={{ color: "#ccc" }}>{user.email}</span>
                </Flex>
              </Flex>
            </div>
          ))}
        </Flex>
      </Form>
    </Modal>
  );
};

export default AddUserToTeamModal;
