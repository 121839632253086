import { createSlice } from "@reduxjs/toolkit";

export interface CounterState {
  list?: [];
}

const initialState: CounterState = {
  list: [],
};
// A mock function to mimic making an async request for data

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const chatListSlice = createSlice({
  name: "chatList",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateChatList: (state, action) => {
      state.list = action.payload;
    },
    addNewChatList: (state: any, action: any) => {
      const listChat = state.list ? state.list : [];
      state.list = [action.payload, ...listChat];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const { updateChatList, addNewChatList } = chatListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default chatListSlice.reducer;
