import React from "react";

const Policy = () => {
  return (
    <div>
      <h1>Chính Sách Bảo Mật</h1>
      <h2>1. Thu Thập Thông Tin</h2>
      <p>
        <strong>Thông Tin Cá Nhân</strong>: Khi bạn đăng ký tài khoản hoặc sử
        dụng dịch vụ của chúng tôi, chúng tôi có thể yêu cầu bạn cung cấp các
        thông tin cá nhân như tên, địa chỉ email, số điện thoại, và địa chỉ.
      </p>
      <p>
        <strong>Thông Tin Sử Dụng</strong>: Chúng tôi có thể thu thập thông tin
        về cách bạn sử dụng dịch vụ của chúng tôi, bao gồm nhưng không giới hạn
        các trang bạn đã xem, thời gian truy cập và các hành động bạn đã thực
        hiện.
      </p>

      <h2>2. Sử Dụng Thông Tin</h2>
      <p>
        <strong>Cung Cấp Dịch Vụ</strong>: Thông tin cá nhân của bạn được sử
        dụng để cung cấp và quản lý dịch vụ mà bạn yêu cầu.
      </p>
      <p>
        <strong>Cải Thiện Dịch Vụ</strong>: Chúng tôi sử dụng thông tin để hiểu
        rõ hơn về nhu cầu của bạn và cải thiện sản phẩm và dịch vụ của chúng
        tôi.
      </p>
      <p>
        <strong>Liên Hệ</strong>: Chúng tôi có thể sử dụng thông tin của bạn để
        liên hệ với bạn về các bản cập nhật dịch vụ, chương trình khuyến mãi, và
        các thông tin quan trọng khác.
      </p>

      <h2>3. Bảo Vệ Thông Tin</h2>
      <p>
        Chúng tôi áp dụng các biện pháp bảo mật hợp lý để bảo vệ thông tin cá
        nhân của bạn khỏi truy cập trái phép, sử dụng sai mục đích hoặc tiết lộ.
      </p>

      <h2>4. Chia Sẻ Thông Tin</h2>
      <p>
        Chúng tôi không chia sẻ thông tin cá nhân của bạn với bên thứ ba trừ khi
        được yêu cầu theo luật pháp hoặc với sự đồng ý của bạn.
      </p>

      <h2>5. Quyền Của Bạn</h2>
      <p>
        Bạn có quyền truy cập, sửa đổi và xóa thông tin cá nhân của mình. Nếu
        bạn có bất kỳ yêu cầu nào về quyền riêng tư, xin vui lòng liên hệ với
        chúng tôi.
      </p>

      <h2>Liên Hệ</h2>
      <p>
        Nếu bạn có bất kỳ câu hỏi nào về chính sách bảo mật này, xin vui lòng
        liên liên hệ với chúng tôi qua email: support@example.com
      </p>
    </div>
  );
};

export default Policy;
