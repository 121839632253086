import React from "react";
import { Button, Spin } from "antd";

const PageLoading = ({ loading }: any) => {
  return (
    <>
      <Spin spinning={loading} fullscreen />
    </>
  );
};

export default PageLoading;
