import { Form, Input as InputAnt, Modal } from "antd";
import { useEffect } from "react";

const EditMediaModal = ({ editMediaModal, handleUpdateInfoMedia }: any) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    if (!handleUpdateInfoMedia)
      return console.error("handleUpdateInfoMedia is not a function");
    handleUpdateInfoMedia(values);
  };

  useEffect(() => {
    if (editMediaModal.visible) {
      form.setFieldsValue(editMediaModal.data);
    }
  }, [editMediaModal.visible]);

  return (
    <Modal
      open={editMediaModal.visible}
      onCancel={editMediaModal.onCancel}
      title="Update media info"
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Title:" name="fileName">
          <InputAnt />
        </Form.Item>
        <Form.Item label="Description:" name="description">
          <InputAnt />
        </Form.Item>
        <Form.Item
          name="id"
          style={{ height: 0, margin: 0, width: 0, visibility: "hidden" }}
        >
          <InputAnt />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditMediaModal;
