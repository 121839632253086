import axiosClient from "./axiosClient";

export const getListTeam = () => {
  const url = "/p/team";
  return axiosClient.get(url);
};

export const createTeam = (params) => {
  const url = "/p/team";
  return axiosClient.post(url, params);
};

export const getTeamUsers = (id) => {
  const url = `/p/team/${id}/users`;
  return axiosClient.get(url);
};

export const assignUsers = (id, params) => {
  const url = `/p/team/${id}/assignUsers`;
  return axiosClient.put(url, params);
};

export const editTeamInfo = (id, params) => {
  const url = `/p/team/${id}`;
  return axiosClient.put(url, params);
};

export const deleteTeam = (id) => {
  const url = `/p/team/${id}`;
  return axiosClient.delete(url);
};
