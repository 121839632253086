import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import StorageKeys from "../constants/storage-keys";
import { REACT_APP_BASE_URL } from "../constants";

interface reponse {
  data?: any;
  code: string;
  message: string;
}

const axiosClient = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptors
// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent
    const token = localStorage.getItem(StorageKeys.TOKEN);
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error: AxiosError) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data) {
      const res: reponse = response.data;
      return res;
    } else {
      const res: AxiosResponse = response;
      return res;
    }
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response?.data === "invalid or expired jwt" ||
      error.response?.status === 401
    ) {
      localStorage.removeItem(StorageKeys.TOKEN);
    }

    return error.response?.data;
  }
);

export default axiosClient;
