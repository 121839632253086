import React from "react";
import Table, { TableProps } from "antd/lib/table";
import PropTypes from "prop-types";
import { useVT } from "virtualizedtableforantd4";

interface SmartTableProps<T> extends TableProps<T> {
  debug?: boolean;
  infinity?: boolean;
  dataSource?: T[];
  scroll?: {
    x?: string | number | true | undefined;
    y?: string | number | undefined;
  };
  onFetch?: () => void;
  lastId?: any;
}

const scrollConfig = {
  y: "65vh",
  x: "auto",
};

const SmartTable = <T extends object>({
  debug = false,
  infinity = false,
  dataSource = [],
  scroll = scrollConfig,
  onFetch = () => null,
  lastId,
  ...props
}: SmartTableProps<T>) => {
  if (!infinity) {
    return <Table dataSource={dataSource} {...props} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [vt] = useVT(
    () => ({
      onScroll: ({ top, isEnd }: { top: number; isEnd: boolean }) => {
        if (isEnd) {
          console.log("loadDataByChunk");
          onFetch();
        }
      },
      scroll: { y: scrollConfig.y },
      debug,
    }),
    [lastId, dataSource]
  );

  return (
    <Table
      dataSource={dataSource}
      scroll={scroll}
      components={vt}
      pagination={false}
      {...props}
    />
  );
};

SmartTable.propTypes = {
  lastId: PropTypes.any,
  dataSource: PropTypes.any,
  debug: PropTypes.bool,
  infinity: PropTypes.bool,
  loadingIndicator: PropTypes.bool,
  onFetch: PropTypes.func,
  scroll: PropTypes.any,
};

export default SmartTable;
