import { Form, Input, Modal } from "antd";
import { toast } from "react-toastify";
import { editTeamInfo } from "../../../../api/teamApi";
import { useEffect } from "react";

const EditTeamModal = ({ editTeamModal, handleEditTeam }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(editTeamModal.data);
    return () => form.resetFields();
  }, [editTeamModal.data, form]);

  return (
    <Modal
      open={editTeamModal.visible}
      onCancel={() => {
        editTeamModal.onCancel();
      }}
      title="Edit Team Info"
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => handleEditTeam(values, editTeamModal?.data?.id)}
      >
        <Form.Item
          name="name"
          label="Name:"
          rules={[{ required: true, message: "Please input your team name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description:"
          rules={[
            { required: true, message: "Please input your team description!" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTeamModal;
