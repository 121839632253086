import { Form, Input, Modal } from "antd";

const CreateTeamModal = ({ createTeamModal, handleCreateTeam }: any) => {
  const [form] = Form.useForm();

  return (
    <Modal
      open={createTeamModal.visible}
      onCancel={createTeamModal.onCancel}
      title="Create New Team"
      onOk={form.submit}
    >
      <Form layout="vertical" form={form} onFinish={handleCreateTeam}>
        <Form.Item
          name="name"
          label="Name:"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description:"
          rules={[
            { required: true, message: "Please input your description!" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTeamModal;
