/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Avatar,
  Button,
  Grid,
  InputBase,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Checkbox, Popconfirm } from "antd";
import moment from "moment";
import { darken } from "polished";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  deleteMedia,
  downloadMedia,
  getListMediaAvailable,
  updateMedia,
  uploadNewMediaFile,
  uploadNewMediaLink,
} from "../../../api/mediaApi";

import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserWithId } from "../../../api/userApi";
import PdfIcon from "../../../assets/image/pdf-icon.svg";
import YtbIcon from "../../../assets/image/ytb-icon.png";
import SmartTable from "../../../components/SmartTable";
import {
  MEDIA_SUB_TYPE,
  MEDIA_SUB_TYPE_NAME,
  MEDIA_TYPE,
} from "../../../constants/mediaType";
import { useModalProps } from "../../../hooks/useModalProps";
import useViewport from "../../../hooks/useViewport";
import CreateMediaModal from "./components/CreateMediaModal";
import EditMediaModal from "./components/EditMediaModal";
import { usePermissionValidate } from "../../../hooks/usePermissionValidate";

const Divider = styled(MuiDivider)(spacing);

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;

const MediaPage = () => {
  const viewPort = useViewport();
  const { isWrite } = usePermissionValidate("f__document");
  // const { user } = useSelector((state: any) => state.user);
  const isMobile = viewPort.width <= 958;
  const location = useLocation();
  const navigate = useNavigate();

  const { t }: any = useTranslation();

  const createMediaModal = useModalProps();
  const editMediaModal = useModalProps();
  const listRef = useRef<any>(null);

  const [listMedia, setListMedia] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [cursor, setCursor] = useState("");
  const [query, setQuery] = useState("");

  const handleCustomRequest = async ({ onSuccess, onError, file }: any) => {
    try {
      const formData = new FormData();
      formData.append("media", file);
      const res: any = await uploadNewMediaFile(formData);
      if (res.code === 200) {
        toast.success(`${file.name} file uploaded successfully.`);
        const listData = await handleGetListedia();
        setListMedia(listData);
      } else {
        toast.error(`Upload file failed.`);
      }
      createMediaModal.onCancel();
      onSuccess();
    } catch (error) {
      toast.error(`Upload file failed.`);
    }
  };

  const handleUploadMediaLink = async (links: any) => {
    try {
      const params = {
        links: links,
        mediaType: "Link",
      };
      const res: any = await uploadNewMediaLink(params);
      if (res.code === 200) {
        const listData = await handleGetListedia();
        setListMedia(listData);
        toast.success(`Links uploaded successfully.`);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleUpload = async () => {
    createMediaModal.onOpen();
  };

  const handleGetListedia = async (cursorId = "", keyword = "") => {
    setLoading(true);
    const data: any = await getListMediaAvailable(cursorId, keyword);
    if (data?.code === 200 && data.data !== null) {
      const activeData = data?.data;
      const dataFormat = activeData?.map(async (items: any) => {
        console.log(items);
        return {
          ...items,
          key: items.id,
          id: items.id,
          name: items.name,
          description: items.description,
          owner: items.owner.fullName,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
          subType: items.subType,
        };
      });
      const listData: any = await Promise.all(dataFormat);

      setHasMore(data?.meta?.has_more);
      if (data?.meta?.has_more) {
        setCursor(data?.meta?.cursor);
      } else {
        setCursor("");
      }
      setLoading(false);
      return listData;
    } else {
      setHasMore(false);
      setCursor("");
      setLoading(false);
      return [];
    }
  };

  const handleDownload = async (item: any) => {
    //Handle for youtube video
    if (
      item.type === MEDIA_TYPE.VIDEO &&
      item.subType === MEDIA_SUB_TYPE.YOUTUBE
    ) {
      const url = item.storageData?.link;
      window.open(url);
    }
    //Handle for file upload
    else {
      const file: any = await downloadMedia(item.id);
      console.log("item", item);
      if (file) {
        const blob = new Blob([file], {
          type: MEDIA_SUB_TYPE_NAME[item.subType],
        });
        const url = window.URL.createObjectURL(blob);
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", item.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    }
  };

  const handlePreview = async (item: any) => {
    if (
      item.type === MEDIA_TYPE.VIDEO &&
      item.subType === MEDIA_SUB_TYPE.YOUTUBE
    ) {
      const url = item.storageData?.link;
      window.open(url);
    } else {
      const file: any = await downloadMedia(item.id);
      if (file) {
        const blob = new Blob([file], {
          type: MEDIA_SUB_TYPE_NAME[item.subType],
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  };

  const handleUpdateInfoMedia = async (values: any) => {
    const params = values;
    const idMedia = params.id;
    delete params.id;
    const res: any = await updateMedia(params, idMedia);
    if (res.code === 200) {
      toast.success("Update media info success!");
      const listData = await handleGetListedia();
      setListMedia(listData);
      editMediaModal.onCancel();
    }
  };

  const handleDeleteMedia = async (idList: any) => {
    if (idList.length === 0) return;
    const res: any = await deleteMedia(idList);
    if (res.code === 200) {
      setSelectedRowKeys([]);
      const listData = await handleGetListedia();
      setListMedia(listData);
      toast.success("Delete media success!");
    } else {
      toast.warning("Server have problem, please try again later");
    }
  };

  const handleCheckMobile = (checked: any, id: any) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, id]);
    } else {
      const newList = selectedRowKeys.filter((val) => val !== id);
      setSelectedRowKeys(newList);
    }
  };

  const handleCheckAllMobile = () => {
    if (listMedia.length === selectedRowKeys.length) {
      setSelectedRowKeys([]);
    } else {
      const listKey = listMedia.map((items: any) => items.id);
      setSelectedRowKeys(listKey);
    }
  };

  const handleFetchMore = async () => {
    if (hasMore) {
      const listData = await handleGetListedia(cursor);
      if (listData.length > 0) {
        setListMedia((prev: any) => [...prev, ...listData]);
      }
    }
  };

  const handleSearch = async (value: any) => {
    setQuery(value);
    if (value !== "") {
      const listData = await handleGetListedia("", value);
      setListMedia(listData);
    } else {
      const listData = await handleGetListedia();
      setListMedia(listData);
    }
  };

  const handleScrollMobile = async (e: any) => {
    if (
      listRef.current.clientHeight + listRef.current.scrollTop ===
        listRef.current.scrollHeight &&
      hasMore
    ) {
      await handleFetchMore();
    }
  };

  const debouncedSearch = _.debounce(handleSearch, 500);

  useEffect(() => {
    debouncedSearch(query);
    return () => {
      debouncedSearch.cancel();
    };
  }, [query]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.style.height = `${window.innerHeight - 200}px`;
    }
  }, [listRef.current]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.addEventListener("scroll", handleScrollMobile);
    }
    return () => {
      if (listRef.current !== null) {
        listRef.current.removeEventListener("scroll", handleScrollMobile);
      }
    };
  }, [listRef.current, hasMore]);

  const columns: any = [
    {
      dataIndex: "name",
      title: "Name",
      width: "25%",
      render: (data: any, row: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <img
            src={row.subType === MEDIA_SUB_TYPE.PDF ? PdfIcon : YtbIcon}
            height={25}
            width={25}
            alt=""
          />
          <span className="limit-2-lines">{data}</span>
        </div>
      ),
    },
    {
      dataIndex: "description",
      title: "Description",
      width: "25%",
    },
    {
      dataIndex: "owner",
      title: "Owner",
      width: "17.5%",
      render: (data: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Avatar>{data[0]}</Avatar>
          {data}
        </div>
      ),
    },
    {
      dataIndex: "updated_at",
      title: "Last Modified",
      width: "17.5%",
    },
    {
      dataIndex: "operation",
      title: "Operation",
      width: "15%",
      render: (_: any, row: any) => {
        return isWrite ? (
          <div style={{ display: "flex", gap: 10 }}>
            <EditOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
              onClick={() => {
                editMediaModal.onOpen({
                  fileName: row.name,
                  description: row.description,
                  id: row.id,
                });
              }}
            />
            <DownloadOutlinedIcon
              fontSize="medium"
              onClick={() => handleDownload(row)}
              className="cursor-pointer"
            />
            <VisibilityOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
              onClick={() => handlePreview(row)}
            />
            {/* <GroupsOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
            /> */}
            <Popconfirm
              placement="topLeft"
              title="Delete media"
              description="Are you sure you want to delete this file?"
              onConfirm={() => handleDeleteMedia([row.id])}
            >
              <DeleteOutlineOutlinedIcon
                fontSize="medium"
                className="cursor-pointer"
              />
            </Popconfirm>
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <div>
      <Grid
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <Grid item style={{ width: "55%" }}>
            <Search>
              <Input
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t("Search")}
                value={query}
              />
            </Search>
          </Grid>
        )}
        {isWrite && (
          <Grid
            item
            style={{
              width: isMobile ? "100%" : "40%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Button
              component="label"
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              style={{ borderRadius: 16, height: 45 }}
              onClick={handleUpload}
            >
              New
            </Button>

            <Popconfirm
              placement="topLeft"
              title="Delete selected media"
              description="Are you sure you want to delete all selected files?"
              onConfirm={() => handleDeleteMedia(selectedRowKeys)}
            >
              <Button
                variant="contained"
                style={{ borderRadius: 16, height: 45 }}
                startIcon={<DeleteOutlineOutlinedIcon />}
              >
                Trash
              </Button>
            </Popconfirm>
          </Grid>
        )}
      </Grid>
      {isMobile ? (
        <>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <div>
              <Checkbox
                onChange={(e) => {
                  handleCheckAllMobile();
                }}
                checked={
                  selectedRowKeys.length === listMedia.length &&
                  listMedia.length > 0
                    ? true
                    : false
                }
              />
            </div>
            <div>|</div>
            <div>Name</div>
          </div>
          <hr />
          <div
            style={{ display: "flex", flexDirection: "column", gap: 5 }}
            ref={listRef}
          >
            {listMedia.map((items: any) => (
              <MediaItemMobile
                key={items.id}
                items={items}
                editMediaModal={editMediaModal}
                handleDownload={handleDownload}
                handlePreview={handlePreview}
                handleDeleteMedia={handleDeleteMedia}
                handleCheckMobile={handleCheckMobile}
                selectedRowKeys={selectedRowKeys}
                isWrite={isWrite}
              />
            ))}
          </div>
        </>
      ) : (
        <SmartTable
          dataSource={listMedia}
          columns={columns}
          loading={loading}
          rowSelection={rowSelection}
          bordered
          pagination={false}
          infinity={true}
          onFetch={handleFetchMore}
          lastId={listMedia[listMedia.length - 1]?.id}
        />
      )}
      <CreateMediaModal
        createMediaModal={createMediaModal}
        handleCustomRequest={handleCustomRequest}
        handleUploadMediaLink={handleUploadMediaLink}
      />
      <EditMediaModal
        editMediaModal={editMediaModal}
        handleUpdateInfoMedia={handleUpdateInfoMedia}
      />
    </div>
  );
};

const MediaItemMobile = ({
  items,
  editMediaModal,
  handleDownload,
  handlePreview,
  handleDeleteMedia,
  handleCheckMobile,
  selectedRowKeys,
  isWrite,
}: any) => {
  const [isShowAction, setIsShowAction] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
          <Checkbox
            checked={selectedRowKeys.includes(items.id) ? true : false}
            onChange={(e) => {
              handleCheckMobile(e.target.checked, items.id);
            }}
          />
          <img
            src={items.subType === MEDIA_SUB_TYPE.PDF ? PdfIcon : YtbIcon}
            height={25}
            width={25}
            alt=""
          />{" "}
          {items.name} - ({items.owner})
        </div>
        {isWrite && (
          <div>
            <MoreHorizOutlinedIcon
              onClick={() => setIsShowAction(!isShowAction)}
            />
          </div>
        )}
      </div>
      {isShowAction && (
        <div style={{ display: "flex", gap: 10, justifyContent: "flex-end" }}>
          <EditOutlinedIcon
            fontSize="medium"
            className="cursor-pointer"
            onClick={() => {
              editMediaModal.onOpen({
                fileName: items.name,
                description: items.description,
                id: items.id,
              });
            }}
          />
          <DownloadOutlinedIcon
            fontSize="medium"
            onClick={() => handleDownload(items.id, items.name)}
            className="cursor-pointer"
          />
          <VisibilityOutlinedIcon
            fontSize="medium"
            className="cursor-pointer"
            onClick={() => handlePreview(items.id, items.name)}
          />
          <Popconfirm
            placement="topLeft"
            title="Delete media"
            description="Are you sure you want to delete this file?"
            onConfirm={() => handleDeleteMedia([items.id])}
          >
            <DeleteOutlineOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
            />
          </Popconfirm>
        </div>
      )}
      <Divider />
    </>
  );
};

export default MediaPage;
