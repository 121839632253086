import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Flex, Form, Input, Modal, Select, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { getListMediaAvailable } from "../../../../api/mediaApi";
import moment from "moment";
import { MODAL_TYPE } from "../../../../constants/modalType";

const CreateAndEditDocumentModal = ({
  createAndEditDocumentModal,
  handleCreateUpdateDocument,
}: any) => {
  const [form] = Form.useForm();

  const [listSelectMedia, setListSelectMedia] = useState<any>([]);

  const onFinish = async (values: any) => {
    if (!handleCreateUpdateDocument) return;
    const res = await handleCreateUpdateDocument(
      values,
      createAndEditDocumentModal.data?.id,
      createAndEditDocumentModal.data?.type
    );
    if (res === true) {
      createAndEditDocumentModal.onCancel();
      form.resetFields();
    }
  };

  const handleGetAllMedia = async (cursorId = "") => {
    let nonSelectedData: any[] = [];
    const data: any = await getListMediaAvailable(cursorId);
    if (data.code === 200 && data.data !== null && data.data.length > 0) {
      nonSelectedData = data.data;
      if (data.meta.has_more) {
        const nextData = await handleGetAllMedia(data.meta.cursor);
        if (!nextData) return nonSelectedData;
        nonSelectedData = [...nonSelectedData, ...nextData];
      }
    }
    return nonSelectedData;
  };

  const handleGetListMedia = async () => {
    let nonSelectedData: any[] = [];
    const data: any = await handleGetAllMedia();
    console.log(
      "data?.data !== null && data?.data?.length > 0",
      data?.data !== null,
      data?.data?.length > 0
    );
    if (data !== null && data?.length > 0) {
      const dataFormat = data.map(async (items: any) => {
        return {
          value: items.id,
          label: items.name,
          owner: items.owner?.fullName,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
        };
      });
      const listMedia: any = await Promise.all(dataFormat);

      nonSelectedData = listMedia.map((media: any) => {
        return {
          label: media.label,
          value: media.value,
          data: media,
        };
      });
    }
    return nonSelectedData;
  };

  const handleEditCreateType = async () => {
    const nonSelectedData: any = await handleGetListMedia();
    if (createAndEditDocumentModal.data?.type === MODAL_TYPE.EDIT) {
      const selectedData: [] = createAndEditDocumentModal?.data?.mediaData?.map(
        (media: any) => {
          return {
            label: media.name,
            value: media.id,
            data: media,
          };
        }
      );

      form.setFieldValue("fileIds", selectedData);
      form.setFieldValue("title", createAndEditDocumentModal?.data?.title);
      form.setFieldValue(
        "description",
        createAndEditDocumentModal?.data?.description
      );
      setListSelectMedia([...selectedData, ...nonSelectedData]);
    } else {
      setListSelectMedia([...nonSelectedData]);
    }
  };

  useEffect(() => {
    if (createAndEditDocumentModal.visible) {
      handleEditCreateType();
    }

    return () => {
      form.resetFields();
      setListSelectMedia([]);
    };
  }, [createAndEditDocumentModal.visible]);

  return (
    <Modal
      open={createAndEditDocumentModal.visible}
      onCancel={() => {
        createAndEditDocumentModal.onCancel();
        form.resetFields();
      }}
      title="Update Document Info"
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Title:"
          name="title"
          rules={[{ required: true, message: "Please input document title!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description:"
          name="description"
          rules={[
            { required: true, message: "Please input document description!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Media List:" name="fileIds">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select media"
            optionLabelProp="label"
            options={listSelectMedia}
            optionRender={(option) => <Space>{option.data.label}</Space>}
            tagRender={() => <></>}
            filterOption={(input, option: any) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
      {createAndEditDocumentModal.data?.type === MODAL_TYPE.EDIT &&
        createAndEditDocumentModal?.data?.mediaData.length > 0 && (
          <Flex vertical gap={5}>
            <Typography.Text strong>Medias in document:</Typography.Text>
            <div style={{ overflow: "scroll", maxHeight: 300 }}>
              {createAndEditDocumentModal?.data?.mediaData?.map(
                (media: any) => (
                  <div key={media.id}>
                    <Flex align="center" gap={5}>
                      <Flex>
                        <InsertDriveFileOutlinedIcon />
                        <span className="ellipsis" style={{ color: "#000" }}>
                          {media.name}
                        </span>
                      </Flex>
                    </Flex>
                  </div>
                )
              )}
            </div>
          </Flex>
        )}
    </Modal>
  );
};

export default CreateAndEditDocumentModal;
