import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import {
  Avatar,
  Button,
  Grid,
  InputBase,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Checkbox, Form, Popconfirm, Table } from "antd";
import moment from "moment";
import { darken } from "polished";
import { useEffect, useRef, useState } from "react";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  downloadMedia,
  getListMedia,
  getMedia,
} from "../../../../api/mediaApi";

import PdfIcon from "../../../../assets/image/pdf-icon.svg";
import { ROLE } from "../../../../constants/role";
import useViewport from "../../../../hooks/useViewport";
import {
  getListDocumentTrash,
  revertDocument,
} from "../../../../api/documentApi";
import { getUserWithId } from "../../../../api/userApi";
import _ from "lodash";
import SmartTable from "../../../../components/SmartTable";

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const DocumentTrash = () => {
  const { user } = useSelector((state: any) => state.user);
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 958;
  const { t }: any = useTranslation();
  const [listDocument, setlistDocument] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [editDocumentForm] = Form.useForm();

  const [listMedia, setListMedia] = useState<any>([]);
  const listRef = useRef<any>(null);

  const [hasMore, setHasMore] = useState(false);
  const [cursor, setCursor] = useState("");
  const [query, setQuery] = useState("");

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleGetListedia = async () => {
    const data: any = await getListMedia();
    if (data.code === 200 && data.data !== null) {
      const activeData = data?.data?.filter(
        (items: any) => items?.status === "active"
      );
      const dataFormat = activeData.map(async (items: any) => {
        return {
          value: items.id,
          label: items.name,
          owner: items.owner?.fullName,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
        };
      });
      const listData: any = await Promise.all(dataFormat);
      setListMedia(listData.reverse());
    } else {
      setListMedia([]);
    }
  };

  const handleGetListDocuments = async (cursorId = "", keyword = "") => {
    setLoading(true);
    const data: any = await getListDocumentTrash(cursorId, keyword);
    if (data.code === 200 && data.data !== null) {
      const activeData = data?.data;
      const dataFormat = activeData?.map(async (items: any) => {
        const mediaList = items.fileIds
          ? items.fileIds.map(async (media: any) => {
              const info = await getMedia(media);
              if (info) {
                return info.data;
              } else {
                return {};
              }
            })
          : [];
        const mediaData = await Promise.all(mediaList);

        return {
          id: items.id,
          title: items.title,
          description: items.description,
          owner: items.owner.fullName,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
          size: "500KB",
          file: "pdf",
          fileIds: items.fileIds,
          key: items.id,
          mediaData: mediaData,
        };
      });
      const listData: any = await Promise.all(dataFormat);

      setHasMore(data?.meta?.has_more);
      if (data?.meta?.has_more) {
        setCursor(data?.meta?.cursor);
      } else {
        setCursor("");
      }
      setLoading(false);
      return listData;
    } else {
      setListMedia([]);
      setHasMore(false);
      setCursor("");
      setLoading(false);
      return [];
    }
  };

  const handleDownload = async (row: any) => {
    if (row.mediaData && row.mediaData.length) {
      row.mediaData.forEach(async (media: any) => {
        const file: any = await downloadMedia(media.id);
        if (file) {
          const blob = new Blob([file], { type: "application/pdf" });
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link: any = document.createElement("a");
          link.href = url;
          link.setAttribute("download", media.name);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      });
    }
  };

  const handleRevertDocument = async (idList: any) => {
    if (idList.length === 0) return;
    const res: any = await revertDocument(idList);
    if (res.code === 200) {
      toast.success("Revert document success!");
      setSelectedRowKeys([]);
      const listData = await handleGetListDocuments();
      setlistDocument(listData);
    } else {
      toast.warning("Server have problem, please try again later");
    }
  };

  const handleCheckMobile = (checked: any, id: any) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, id]);
    } else {
      const newList = selectedRowKeys.filter((val) => val !== id);
      setSelectedRowKeys(newList);
    }
  };

  const handleCheckAllMobile = () => {
    if (listDocument.length === selectedRowKeys.length) {
      setSelectedRowKeys([]);
    } else {
      const listKey = listDocument.map((items: any) => items.id);
      setSelectedRowKeys(listKey);
    }
  };

  const handleFetchMore = async () => {
    if (hasMore) {
      const listData = await handleGetListDocuments(cursor);
      setlistDocument((prev: any) => [...prev, ...listData]);
    }
  };

  const handleSearch = async (value: any) => {
    setQuery(value);

    if (value !== "") {
      const listData = await handleGetListDocuments("", value);
      setlistDocument(listData);
    } else {
      const listData = await handleGetListDocuments();
      setlistDocument(listData);
    }
  };

  const handleScrollMobile = async (e: any) => {
    if (
      listRef.current.clientHeight + listRef.current.scrollTop ===
        listRef.current.scrollHeight &&
      hasMore
    ) {
      await handleFetchMore();
    }
  };

  const debouncedSearch = _.debounce(handleSearch, 500);

  useEffect(() => {
    debouncedSearch(query);
    return () => {
      debouncedSearch.cancel();
    };
  }, [query]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.style.height = `${window.innerHeight - 200}px`;
    }
  }, [listRef]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.addEventListener("scroll", handleScrollMobile);
    }
    return () => {
      if (listRef.current !== null) {
        listRef.current.removeEventListener("scroll", handleScrollMobile);
      }
    };
  }, [listRef.current, hasMore]);

  useEffect(() => {
    handleGetListedia();
  }, []);

  const columns: any = [
    {
      dataIndex: "title",
      title: "Title",
      width: 300,
      renderCell: (data: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            {data}
          </div>
        );
      },
    },
    {
      dataIndex: "owner",
      title: "Owner",
      width: 300,
      renderCell: (data: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Avatar>H</Avatar>
          {data}
        </div>
      ),
    },
    {
      dataIndex: "description",
      title: "Description",
      // width: 400,
    },
    {
      dataIndex: "updated_at",
      title: "Last Modified",
      // width: 200,
    },
    // {
    //   dataIndex: "size",
    //   title: "Size",
    //   width: 200,
    // },
    {
      dataIndex: "operation",
      title: "Operation",
      // width: 200,
      render: (_: any, row: any) => {
        return (
          <div style={{ display: "flex", gap: 10, cursor: "pointer" }}>
            <DownloadOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
              onClick={() => handleDownload(row)}
            />
            {(row.owner === user?.fullName || user?.role === ROLE.OWNER) && (
              <Popconfirm
                placement="topLeft"
                title="Share"
                description="Are you sure you want to revert this document?"
                onConfirm={() => handleRevertDocument([row?.id])}
              >
                <HistoryOutlinedIcon
                  fontSize="medium"
                  className="cursor-pointer"
                />
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Grid
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <Grid item style={{ width: "55%" }}>
            <Search>
              <Input
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t("Search")}
                value={query}
              />
            </Search>
          </Grid>
        )}
        <Grid
          item
          style={{
            width: isMobile ? "100%" : "40%",
            display: "flex",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <Popconfirm
            placement="topLeft"
            title="Share"
            description="Are you sure you want to revert all selected documents?"
            onConfirm={() => handleRevertDocument(selectedRowKeys)}
          >
            <Button
              variant="contained"
              style={{ borderRadius: 16, height: 45 }}
              startIcon={<HistoryOutlinedIcon />}
            >
              Revert
            </Button>
          </Popconfirm>
        </Grid>
      </Grid>
      <div>
        {isMobile ? (
          <>
            <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <div>
                <Checkbox
                  onChange={(e) => {
                    handleCheckAllMobile();
                  }}
                  checked={
                    selectedRowKeys.length === listDocument.length &&
                    listDocument.length > 0
                      ? true
                      : false
                  }
                />
              </div>
              <div>|</div>
              <div>Name</div>
            </div>
            <hr />
            <div
              style={{ display: "flex", flexDirection: "column", gap: 5 }}
              ref={listRef}
            >
              {listDocument.map((items: any) => (
                <DocumentItem
                  key={items.id}
                  items={items}
                  form={editDocumentForm}
                  handleDownload={handleDownload}
                  handleRevertDocument={handleRevertDocument}
                  handleCheckMobile={handleCheckMobile}
                  selectedRowKeys={selectedRowKeys}
                />
              ))}
            </div>
          </>
        ) : (
          <SmartTable
            dataSource={listDocument}
            columns={columns}
            loading={loading}
            rowSelection={rowSelection}
            bordered
            scroll={{ y: "63vh" }}
            // expandable={{
            //   expandedRowRender: (record: any) => (
            //     <div
            //       style={{
            //         paddingLeft: 20,
            //         display: "flex",
            //         flexDirection: "column",
            //         gap: 5,
            //       }}
            //     >
            //       {record.mediaData.map((media: any) => (
            //         <div
            //           style={{ display: "flex", alignItems: "center", gap: 5 }}
            //         >
            //           <VisibilityOutlinedIcon
            //             fontSize="medium"
            //             className="cursor-pointer"
            //             onClick={() => handlePreview(media)}
            //           />
            //           <img src={PdfIcon} height={25} width={25} alt="" />
            //           {media?.name} - {media?.owner?.fullName}
            //         </div>
            //       ))}
            //     </div>
            //   ),
            // }}
            pagination={false}
            infinity={true}
            onFetch={handleFetchMore}
            lastId={listMedia[listMedia.length - 1]?.id}
          />
        )}
      </div>
    </div>
  );
};

const DocumentItem = ({
  items,
  EditMediaModal,
  form,
  handleDownload,
  handleRevertDocument,
  handleCheckMobile,
  selectedRowKeys,
}: any) => {
  const [isShowAction, setIsShowAction] = useState(false);
  const { user } = useSelector((state: any) => state.user);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
          <Checkbox
            checked={selectedRowKeys.includes(items.id) ? true : false}
            onChange={(e) => {
              handleCheckMobile(e.target.checked, items.id);
            }}
          />
          <FolderCopyOutlinedIcon /> {items.title} ({items.owner})
        </div>
        <div>
          <MoreHorizOutlinedIcon
            onClick={() => setIsShowAction(!isShowAction)}
          />
        </div>
      </div>
      {isShowAction && (
        <div
          style={{
            display: "flex",
            gap: 10,
            cursor: "pointer",
            justifyContent: "flex-end",
          }}
        >
          <EditOutlinedIcon
            fontSize="medium"
            onClick={() => {
              EditMediaModal.onOpen();
              form.setFieldsValue({
                title: items.title,
                description: items.description,
                id: items.id,
              });
            }}
            className="cursor-pointer"
          />
          <DownloadOutlinedIcon
            fontSize="medium"
            className="cursor-pointer"
            onClick={() => handleDownload(items)}
          />
          {(items.owner === user?.fullName || user?.role === ROLE.OWNER) && (
            <Popconfirm
              placement="topLeft"
              title="Share"
              description="Are you sure you want to revert this document?"
              onConfirm={() => handleRevertDocument([items?.id])}
            >
              <HistoryOutlinedIcon
                fontSize="medium"
                className="cursor-pointer"
              />
            </Popconfirm>
          )}
        </div>
      )}
      <Divider />
    </>
  );
};

export default DocumentTrash;
