import axiosClient from "./axiosClient";

export const loginUser = (params) => {
  const url = "/o/login";
  return axiosClient.post(url, params);
};

export const registerUser = (params) => {
  const url = "/o/register";
  return axiosClient.post(url, params);
};
export const siginGoogle = ({ callback, slotCode }) => {
  const url = `/o/authorize/google?callback=${callback}&slotCode=${slotCode}`;
  return url;
};

export const createUser = (params) => {
  const url = "/p/user";
  return axiosClient.post(url, params);
};

export const getAllUser = () => {
  const url = "/p/user";
  return axiosClient.get(url);
};

export const getMyRole = () => {
  const url = "/p/user/me/roles";
  return axiosClient.get(url);
};

export const updateUserInfo = (id, params) => {
  const url = `/p/user/${id}`;
  return axiosClient.put(url, params);
};
export const updateStatusUser = (params) => {
  const url = `/p/user/updateStatus`;
  return axiosClient.put(url, params);
};

export const updateMyPassword = (params) => {
  const url = `/p/user/me/updatePassword`;
  return axiosClient.put(url, params);
};

export const getUserWithId = (id) => {
  const url = `/p/user/${id}`;
  return axiosClient.get(url);
};

export const updateUserRole = (id, roleId) => {
  const url = `/p/user/${id}/role/${roleId}`;
  return axiosClient.put(url);
};

export const getUserRoleWithId = (id) => {
  const url = `/p/user/${id}/roles`;
  return axiosClient.get(url);
};
