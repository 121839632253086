import React, { useEffect, useState } from "react";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { Form, Modal, Select } from "antd";
import { getListRole } from "../../../../api/roleApi";
import { toast } from "react-toastify";

const EditRoleUserModal = ({
  editRoleUserModal,
  handleUpdateUserRole,
}: any) => {
  const [form] = Form.useForm();

  const [listRole, setListRole] = useState([]);

  const handleGetListRole = async () => {
    console.log("editRoleUserModal.data", editRoleUserModal.data);
    const res: any = await getListRole();
    if (res.code === 200) {
      const rolesCheck = res.data.filter((role: any) => {
        return role.name !== editRoleUserModal.data?.roleName;
      });
      const roles = rolesCheck.map((role: any) => {
        return {
          value: role.id,
          label: role.name,
        };
      });
      setListRole(roles);
    } else {
      toast.warning("Server have a problem, please try again later!");
    }
  };

  const onFinish = async (values: any) => {
    if (!handleUpdateUserRole) return;
    const res = await handleUpdateUserRole(
      editRoleUserModal.data?.id,
      values.role_id,
      editRoleUserModal.data?.roleID
    );
    if (res === true) {
      editRoleUserModal.onCancel();
      form.resetFields();
    }
  };

  useEffect(() => {
    handleGetListRole();
  }, [editRoleUserModal.visible]);

  return (
    <Modal
      open={editRoleUserModal.visible}
      onCancel={() => {
        editRoleUserModal.onCancel();
        form.resetFields();
      }}
      title="Update User Role"
      onOk={form.submit}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="role_id"
          rules={[{ required: true, message: "Please select role!" }]}
        >
          <Select
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={listRole}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRoleUserModal;
