import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
const pagesSection = [
  {
    href: "/media-list",
    icon: PermMediaOutlinedIcon,
    title: "Media",
    role: ["f__media-p__read", "f__all"],
  },
  {
    href: "/document-list",
    icon: FolderOpenOutlinedIcon,
    title: "Document",
    role: ["f__document-p__read", "f__all"],
  },
  {
    href: "/trash-list",
    icon: AutoDeleteOutlinedIcon,
    title: "Trash",
    role: [
      "f__document-p__read",
      "f__document-p__write",
      "f__media-p__write",
      "f__media-p__read",
      "f__all",
    ],
  },
  {
    href: "/user-list",
    icon: Person2OutlinedIcon,
    title: "Users",
    role: ["f__user-p__read", "f__all"],
  },
  {
    href: "/slot-list",
    icon: AppRegistrationOutlinedIcon,
    title: "Slots",
    role: ["f__all"],
  },
  // {
  //   href: "/setting",
  //   icon: SettingsIcon,
  //   title: "Setting",
  // },
] as any;

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default navItems;
