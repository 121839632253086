import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { permissionsRole } from "../../api/roleApi";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

export const permission: any = createAsyncThunk("/permission", async () => {
  try {
    const data: any = await permissionsRole();
    if (data?.code !== 200) {
      return {};
    } else {
      const permissionList = data.data;
      const permissionFromat = Object.keys(permissionList).map(async (key) => {
        const childrenFormat = permissionList[key].map(async (item: any) => {
          return {
            title: `${capitalizeFirstLetter(item.slice(3))}`,
            key: `${key}-${item}`,
          };
        });
        const children = await Promise.all(childrenFormat);
        return {
          title: `${capitalizeFirstLetter(key.slice(3))} Page`,
          key: key,
          children: children,
        };
      });
      const permissionFromatList = await Promise.all(permissionFromat);
      return permissionFromatList;
    }
  } catch (error) {
    console.log(error);
  }
});

export const permissionListSlice = createSlice({
  name: "permissionList",
  initialState: {},
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updatePermissionList: (state, action) => {
      return (state = action.payload);
    },
  },
  extraReducers: {
    [permission.fulfilled]: (state: any, action: any) => {
      return (state = action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const { updatePermissionList } = permissionListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default permissionListSlice.reducer;
