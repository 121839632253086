import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMyRole, loginUser } from "../../api/userApi";
import StorageKeys from "../../constants/storage-keys";

export interface UserState {
  user: any;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

export const login: any = createAsyncThunk("/login", async (payload) => {
  try {
    const data: any = await loginUser(payload);
    if (data?.code !== 200) {
      return data.message;
    } else {
      const { accessToken } = data.data;
      localStorage.setItem("accessToken", accessToken);
      const userData = data.data;
      const roleRes: any = await getMyRole();
      if (
        roleRes.code === 200 &&
        roleRes?.data[0]?.normalizedName &&
        roleRes?.data[0]?.permissions
      ) {
        const permissionList = roleRes?.data[0]?.permissions;
        const permissionListFromat: any = [];

        await Promise.all(
          Object.keys(permissionList).map(async (f) => {
            permissionListFromat.push(f);
            if (permissionList[f].length > 0) {
              await Promise.all(
                permissionList[f].map(async (p: string) => {
                  permissionListFromat.push(`${f}-${p}`);
                })
              );
            }
          })
        );

        userData.permissions = permissionListFromat;
        userData.role = roleRes?.data[0]?.normalizedName;
      }
      userData.code = 200;
      delete userData.accessToken;
      return userData;
    }
  } catch (error) {
    console.log("error22", error);
  }
});

export const loginSocial: any = createAsyncThunk(
  "/login",
  async (payload: any) => {
    try {
      const { accessToken } = payload;
      localStorage.setItem("accessToken", accessToken);
      const userData = payload;
      const roleRes: any = await getMyRole();
      if (
        roleRes.code === 200 &&
        roleRes?.data[0]?.normalizedName &&
        roleRes?.data[0]?.permissions
      ) {
        const permissionList = roleRes?.data[0]?.permissions;
        const permissionListFromat: any = [];

        await Promise.all(
          Object.keys(permissionList).map(async (f) => {
            permissionListFromat.push(f);
            if (permissionList[f].length > 0) {
              await Promise.all(
                permissionList[f].map(async (p: string) => {
                  permissionListFromat.push(`${f}-${p}`);
                })
              );
            }
          })
        );

        userData.permissions = permissionListFromat;
        userData.role = roleRes?.data[0]?.normalizedName;
      }
      userData.code = 200;
      delete userData.accessToken;
      return userData;
    } catch (error) {
      console.log("error22", error);
    }
  }
);

export const userSlices = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      localStorage.removeItem(StorageKeys.TOKEN);
      localStorage.removeItem("current-chat-id");
      state.user = {};
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? "Login failed";
    });
  },
});

export const { logout } = userSlices.actions;

export default userSlices.reducer;
