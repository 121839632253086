import styled from "@emotion/styled";
import { rgba } from "polished";
import React, { forwardRef, useEffect, useState } from "react";
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Chip,
  Collapse,
  ListItemButton,
  ListItemProps,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { haveCommonElement } from "../../utils/haveCommonElement";

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

type ItemType = {
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  depth: number;
};

const Item = styled(ListItemButton)<ItemType>`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  border-radius: 6px;
  margin-bottom: ${(props) => props.theme.spacing(0.5)};

  svg {
    // color: ${(props) => props.theme.sidebar.color};
    color: #000;
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background: rgba(0, 0, 0, 0.08);
    span {
      // color: ${(props) => props.theme.sidebar.color};
      font-weight: bold;
    }
  }
`;

type TitleType = {
  depth: number;
};

// color: ${(props) =>
//   rgba(
//     props.theme.sidebar.color,
//     props.depth && props.depth > 0 ? 0.7 : 1
//   )};
const Title = styled(ListItemText)<TitleType>`
  margin: 0;
  span {
    color: #000;
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

type SidebarNavListItemProps = ListItemProps & {
  className?: string;
  depth: number;
  href: string;
  icon: React.FC<any>;
  badge?: string;
  open?: boolean;
  title: string;
  rolePage: string[];
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
    rolePage,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.user);
  const [open, setOpen] = useState(openProp);
  const [isShow, setIsShow] = useState(false);

  const handleToggle = () => {
    setOpen((state) => !state);
  };
  useEffect(() => {
    if (rolePage.length === 0) {
      setIsShow(true);
    } else {
      if (user?.permissions && haveCommonElement(user?.permissions, rolePage)) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    }
  }, [rolePage, user?.permissions]);

  useEffect(() => {
    if (location.pathname === href && user?.permissions.length > 0) {
      if (!haveCommonElement(user?.permissions, rolePage)) {
        navigate("auth/404");
      }
    }
  }, [location.pathname]);

  if (isShow === false) {
    return <></>;
  }

  if (children) {
    return (
      <React.Fragment>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {title}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
