import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { downloadMedia, getMedia } from "../../../../api/mediaApi";
import { MEDIA_SUB_TYPE, MEDIA_TYPE } from "../../../../constants/mediaType";
import { useSelector } from "react-redux";

const ChatMessage = styled.div<{ position: "left" | "right" }>`
  margin: 5px;
  text-align: ${(props) => props.position};
  margin-left: ${(props) => (props.position === "right" ? "15px" : "15px")};
  margin-right: ${(props) => (props.position === "left" ? "15px" : "15px")};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageBubble = styled.div<{ highlighted: boolean }>`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted ? props.theme.palette.secondary.main : "transparent"};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

interface ChatMessageComponentType {
  message: string;
  position: "left" | "right";
  documentId?: [];
  chatBoxRef?: any;
}

const ChatMessageComponent = ({
  message,
  position = "left",
  documentId,
}: ChatMessageComponentType) => {
  const [media, setMedia] = useState<any>([]);
  const { user } = useSelector((state: any) => state.user);
  const handleGetPost = async () => {
    if (!documentId || documentId.length === 0) {
      return;
    }

    const listDocument: any = documentId.map(async (id: any) => {
      if (id.length <= 0) return;
      const res: any = await getMedia(id);
      return res;
    });
    const list: any = await Promise.all(listDocument);
    setMedia(list);
  };

  const handlePreview = async (data: any) => {
    const isExistDownload =
      user?.permissions.includes("f__all-p__all") ||
      user?.permissions.includes("f__media-p__download");
    if (!isExistDownload) return;
    if (data) {
      if (
        data.type === MEDIA_TYPE.VIDEO ||
        data.subType === MEDIA_SUB_TYPE.YOUTUBE
      ) {
        const url = data.storageData?.link;
        window.open(url);
      } else {
        const file: any = await downloadMedia(data.id);
        if (file) {
          const blob = new Blob([file], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }
      }
    }
  };

  const renderTextWithBold = (text: string): JSX.Element => {
    // Sử dụng regex để tìm và thay thế phần văn bản trong dấu "**"
    const parts = text.split(/(\*\*[^*]+\*\*)/g).map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        // Nếu phần này nằm trong dấu "**", bôi đen nó
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else {
        // Nếu không, giữ nguyên phần văn bản này
        return <React.Fragment key={index}>{part}</React.Fragment>;
      }
    });

    return <span>{parts}</span>;
  };

  useEffect(() => {
    handleGetPost();
  }, [documentId]);

  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageBubble highlighted={position === "right"}>
          <Typography style={{ whiteSpace: "pre-line" }}>
            {renderTextWithBold(message)}
          </Typography>
        </ChatMessageBubble>
      </ChatMessageInner>
      {position === "left" && documentId?.length && media.length > 0 && (
        <div>
          <div
            style={{
              backgroundColor: "rgba(249,250,254)",
              padding: 10,
              borderRadius: 8,
            }}
          >
            <span
              style={{
                fontSize: 14,
                marginBottom: 10,
                display: "inline-block",
              }}
            >
              Sources
            </span>
            {media.map((m: any, idx: number) => (
              <Typography
                variant="h6"
                className="cursor-pointer"
                onClick={() => handlePreview(m?.data)}
                key={m.id ? m.id + idx : idx}
              >
                {idx + 1}.{m?.data?.name}
              </Typography>
            ))}
          </div>
        </div>
      )}
    </ChatMessage>
  );
};

export default React.memo(ChatMessageComponent);
