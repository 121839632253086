import axiosClient from "./axiosClient";

export const getListSlots = (mode) => {
  const url = `/p/slot?mode=flat`;
  return axiosClient.get(url);
};

export const downloadAllSlot = () => {
  const url = `/p/slot?mode=download`;
  return axiosClient.get(url, {
    responseType: "blob",
  });
};

export const quickCreateSlot = (params) => {
  const url = `/p/slot/quickCreate`;

  return axiosClient.post(url, params, {
    responseType: "blob",
  });
};
export const getUserInSlot = (id) => {
  const url = `/p/slot/${id}/users`;
  return axiosClient.get(url);
};

export const uploauploadExpirationdSlot = (id) => {
  const url = `/p/slot/uploadExpiration`;
  return axiosClient.patch(url);
};
