/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const usePermissionValidate = (page: string) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.user);

  const isRead = useMemo(
    () =>
      user?.permissions.includes(`${page}-p__read`) ||
      user?.permissions.includes("f__all-p__all"),
    [page]
  );

  const isWrite = useMemo(
    () =>
      user?.permissions.includes(`${page}-p__write`) ||
      user?.permissions.includes("f__all-p__all"),
    [page]
  );

  useEffect(() => {
    if (isRead === false) {
      navigate("auth/404");
    }
  }, [isRead, user?.permissions]);

  return {
    isWrite,
    isRead,
  };
};
