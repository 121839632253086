import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@mui/icons-material";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Segmented,
  Upload,
  UploadProps,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

const options = ["File", "Link"];

const CreateMediaModal = ({
  createMediaModal,
  handleCustomRequest,
  handleUploadMediaLink,
}: any) => {
  const [form] = Form.useForm();
  const [uploadType, setUploadType] = useState("File");

  const props: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: false,
    // beforeUpload: (file) => {
    //   const PDF = file.type === "application/pdf";
    //   if (!PDF) {
    //     toast.error(`${file.name} is not a pdf file`);
    //   }
    //   return PDF || Upload.LIST_IGNORE;
    // },
    customRequest: handleCustomRequest,
  };

  const onFinish = async (values: any) => {
    if (!values.links || !handleUploadMediaLink) return;

    const res = await handleUploadMediaLink(values.links);
    if (res === true) {
      form.resetFields();
      createMediaModal.onCancel();
      setUploadType("File");
    } else {
      toast.error(`Upload links failed.`);
    }
  };

  const isYouTubeUrl = (url: string) => {
    var regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return regex.test(url);
  };

  const debouncedChangeHandler = debounce(async (url, key) => {
    if (isYouTubeUrl(url)) {
      const urlObj = new URL(url);
      const videoId = urlObj.searchParams.get("v");
      const oembedBaseUrl = "https://www.youtube.com/oembed";
      const oembedUrl = new URL(oembedBaseUrl);
      oembedUrl.searchParams.append("url", `youtube.com/watch?v=${videoId}`);
      oembedUrl.searchParams.append("format", "json");

      const res: any = await fetch(oembedUrl.href).then((res) => res.json());
      form.setFieldValue(key, res.title);
    }
  }, 1000);

  const handleRemoveLink = (key: any) => {
    form.setFieldValue(key, "");
  };

  const handleChangeInput = (e: any, item: any) => {
    debouncedChangeHandler(e.target.value, ["links", item.index, "title"]);
  };

  return (
    <Modal
      open={createMediaModal.visible}
      onCancel={createMediaModal.onCancel}
      title="Create New Media"
      footer={false}
      className="create-media-modal"
    >
      <div style={{ minHeight: 230 }}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Segmented
            options={options}
            onChange={(val: any) => setUploadType(val)}
            value={uploadType}
            size="large"
            // block={true}
          />
        </div>
        <div>
          {uploadType === "File" ? (
            <div style={{ padding: "10px" }}>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </p>
              </Dragger>
            </div>
          ) : (
            <div style={{ overflow: "auto", maxHeight: "40vh" }}>
              <Form
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                style={{ maxWidth: 600, padding: "10px" }}
                autoComplete="off"
                form={form}
                initialValues={{ links: [{}] }}
              >
                <Form.List name="links">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Flex
                          key={key}
                          align="center"
                          justify="center"
                          gap={10}
                          style={{
                            borderBottom: "1px solid grey",
                            marginBottom: 10,
                          }}
                        >
                          <Flex vertical style={{ width: "100%" }}>
                            <Form.Item
                              {...restField}
                              name={[name, "url"]}
                              rules={[
                                { required: true, message: "Missing url" },
                              ]}
                            >
                              <Input
                                placeholder="Enter url"
                                onChange={(e: any) => {
                                  const params = {
                                    key,
                                    name,
                                    index,
                                    ...restField,
                                  };
                                  handleChangeInput(e, params);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "title"]}
                              rules={[
                                { required: true, message: "Missing title" },
                              ]}
                            >
                              <Input placeholder="Title" />
                            </Form.Item>
                          </Flex>
                          <CloseOutlined
                            style={{ marginTop: "-20px" }}
                            onClick={() => {
                              handleRemoveLink([name, "title"]);
                              remove(name);
                            }}
                          />
                        </Flex>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add more link
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Form.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CreateMediaModal;
