import React, { useEffect } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import MediaTrash from "./components/MediaTrash";
import DocumentTrash from "./components/DocumentTrash";
import { useLocation, useNavigate } from "react-router-dom";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Media",
    children: <MediaTrash />,
  },
  {
    key: "2",
    label: "Documents",
    children: <DocumentTrash />,
  },
];

const Trash: React.FC = () => {
  const onChange = () => {};
  return (
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} size="large" />
  );
};

export default Trash;
