import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import { Button, Grid, InputBase, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { Avatar, Flex, Popconfirm, Table, Tooltip } from "antd";
import { darken } from "polished";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  assignUsers,
  createTeam,
  deleteTeam,
  editTeamInfo,
  getListTeam,
  getTeamUsers,
} from "../../../../api/teamApi";
import { useModalProps } from "../../../../hooks/useModalProps";
import useViewport from "../../../../hooks/useViewport";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import AddUserToTeamModal from "./AddUserToTeamModal";
import CreateTeamModal from "./CreateTeamModal";
import EditTeamModal from "./EditTeamModal";

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;
const Divider = styled(MuiDivider)(spacing);

const TeamManage = () => {
  const { user } = useSelector((state: any) => state.user);
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 958;
  const createTeamModal = useModalProps();
  const editTeamModal = useModalProps();
  const addUserToTeamModal = useModalProps();
  const { t }: any = useTranslation();
  const [listTeamRow, setListTeamrRow] = useState<any>([]);
  const [listTeamRowSearch, setListTeamrRowSearch] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    handleGetAllTeam();
  }, []);

  const handleGetAllTeam = async () => {
    try {
      setLoading(true);
      const { data } = await getListTeam();
      const dataFilter = data?.filter((team: any) => team.name !== "Public");
      const dataFormat = dataFilter.map(async (team: any) => {
        const userTeam = await getTeamUsers(team.id);
        return {
          key: team.id,
          id: team.id,
          name: team.name,
          description: team.description,
          users: userTeam?.data?.length ? userTeam.data : [],
        };
      });
      const dataAfterFormat = await Promise.all(dataFormat);

      console.log("dataAfterFormat", dataAfterFormat);
      setListTeamrRow(dataAfterFormat);
      setListTeamrRowSearch(dataAfterFormat);
      setLoading(false);
    } catch (error) {
      setListTeamrRow([]);
      setListTeamrRowSearch([]);
      setLoading(false);
    }
  };

  const handleOpenModalCreateTeam = () => {
    createTeamModal.onOpen();
  };

  const handleCreateTeam = async (values: any) => {
    const params = {
      name: values.name,
      description: values.description,
    };
    const res: any = await createTeam(params);
    if (res.code === 200) {
      handleGetAllTeam();
      createTeamModal.onCancel();
      toast.success("Create new team success!");
    }
  };

  const handleSearch = (e: any) => {
    const newList = listTeamRow.filter((user: any) =>
      user.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setListTeamrRowSearch(newList);
  };

  const handleAddUserToTeam = async (
    userIDs: any,
    id: string,
    style: string
  ) => {
    try {
      if (userIDs.length === 0 || !id || !style) return;
      const params = {
        isAssign: style === "add" ? true : false,
        userIDs: userIDs,
      };
      const res: any = await assignUsers(id, params);
      if (res.code === 200) {
        addUserToTeamModal.onCancel();
        handleGetAllTeam();
        toast.success(
          style === "add"
            ? "Add user to team success!"
            : "Remove user from team success!"
        );
      } else {
        addUserToTeamModal.onCancel();
        toast.error(
          style === "add"
            ? "Add user to team failed!"
            : "Remove user from team failed!"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditTeam = async (values: any, id: string) => {
    try {
      const params = {
        name: values.name,
        description: values.description,
      };
      const res: any = await editTeamInfo(id, params);
      if (res.code === 200) {
        editTeamModal.onCancel();
        handleGetAllTeam();
        toast.success("Update team info was successful");
      } else {
        toast.error("Update team info was failed");
        editTeamModal.onCancel();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteTeam = async (id: string) => {
    try {
      const res: any = await deleteTeam(id);
      if (res.code === 200) {
        handleGetAllTeam();
        toast.success("Delete team success!");
      } else {
        toast.error(
          res?.message
            ? capitalizeFirstLetter(res.message)
            : "Delete team failed!"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns: any = [
    {
      dataIndex: "name",
      key: "name",
      title: "Teams",
      width: 300,
      renderCell: (row: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Avatar>{row.value[0]}</Avatar>
          {row.value}
        </div>
      ),
    },
    {
      dataIndex: "users",
      key: "users",
      title: "Users",
      width: 150,
      render: (data: any, row: any) => {
        return (
          <Tooltip title="User" placement="top">
            <Avatar.Group
              maxCount={3}
              maxStyle={{ color: "#d8e2f6", backgroundColor: "#376fd0" }}
              shape="circle"
              maxPopoverPlacement="bottom"
            >
              {data.map((d: any, key: number) => {
                if (key < 3) {
                  return (
                    <Tooltip title={d.fullName} placement="top">
                      <Avatar>{d.fullName[0]}</Avatar>
                    </Tooltip>
                  );
                } else {
                  return (
                    <Flex
                      gap="small"
                      align="center"
                      style={{ marginBottom: 5 }}
                    >
                      <Avatar>{d.fullName[0]}</Avatar> {d.fullName}
                    </Flex>
                  );
                }
              })}
            </Avatar.Group>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "operation",
      key: "operation",
      title: "Operation",
      render: (data: any, row: any) => {
        return (
          <div style={{ display: "flex", gap: 10 }}>
            <EditOutlinedIcon
              fontSize="medium"
              onClick={() => {
                const paramsProps = {
                  name: row.name,
                  description: row.description,
                  id: row.id,
                };
                editTeamModal.onOpen({ ...paramsProps });
              }}
              className="cursor-pointer"
            />
            <PersonAddAltOutlinedIcon
              fontSize="medium"
              onClick={() => {
                addUserToTeamModal.onOpen({
                  users: row.users,
                  id: row.id,
                  style: "add",
                });
              }}
              className="cursor-pointer"
            />
            <PersonRemoveOutlinedIcon
              fontSize="medium"
              onClick={() => {
                addUserToTeamModal.onOpen({
                  users: row.users,
                  id: row.id,
                  style: "remove",
                });
              }}
              className="cursor-pointer"
            />
            <Popconfirm
              placement="topLeft"
              title="Delete"
              description={`Are you sure to delete team?`}
              onConfirm={() => {
                handleDeleteTeam(row.id);
              }}
            >
              <DeleteOutlineOutlinedIcon fontSize="medium" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Grid
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <Grid item style={{ width: "55%" }}>
            <Search>
              <Input
                placeholder={t("Search")}
                onChange={(e) => handleSearch(e)}
              />
            </Search>
          </Grid>
        )}
        <Grid
          item
          style={{
            width: isMobile ? "100%" : "40%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpenModalCreateTeam}
            style={{ borderRadius: 16, height: 45 }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add New Team
          </Button>
        </Grid>
      </Grid>
      {isMobile ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          {listTeamRowSearch.map((items: any) => (
            <UserItem
              items={items}
              editTeamModal={editTeamModal}
              addUserToTeamModal={addUserToTeamModal}
              handleDeleteTeam={handleDeleteTeam}
            />
          ))}
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={listTeamRowSearch}
          columns={columns}
          rowSelection={rowSelection}
          bordered
        />
      )}

      <CreateTeamModal
        createTeamModal={createTeamModal}
        handleCreateTeam={handleCreateTeam}
      />

      <EditTeamModal
        editTeamModal={editTeamModal}
        handleEditTeam={handleEditTeam}
      />

      <AddUserToTeamModal
        addUserToTeamModal={addUserToTeamModal}
        handleAddUserToTeam={handleAddUserToTeam}
      />
    </div>
  );
};

const UserItem = ({
  items,
  editTeamModal,
  addUserToTeamModal,
  handleDeleteTeam,
}: any) => {
  const [isShowAction, setIsShowAction] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          {items.name}
        </div>
        <div>
          <MoreHorizOutlinedIcon
            onClick={() => setIsShowAction(!isShowAction)}
          />
        </div>
      </div>
      {isShowAction && (
        <div
          style={{
            display: "flex",
            gap: 10,
            cursor: "pointer",
            justifyContent: "flex-end",
          }}
        >
          <EditOutlinedIcon
            fontSize="medium"
            onClick={() => {
              const paramsProps = {
                name: items.name,
                description: items.description,
                id: items.id,
              };
              editTeamModal.onOpen({ ...paramsProps });
            }}
            className="cursor-pointer"
          />

          <GroupAddOutlinedIcon
            fontSize="medium"
            onClick={() => {
              addUserToTeamModal.onOpen({ users: items.users, id: items.id });
            }}
            className="cursor-pointer"
          />
          <Popconfirm
            placement="topLeft"
            title="Delete"
            description={`Are you sure to delete team?`}
            onConfirm={() => {
              handleDeleteTeam(items.id);
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="medium" />
          </Popconfirm>
        </div>
      )}
      <Divider />
    </>
  );
};

export default TeamManage;
