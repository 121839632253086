import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { Button, Grid, InputBase, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import {
  Avatar,
  Flex,
  Form,
  Table,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import moment from "moment";
import { darken } from "polished";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  downloadAllSlot,
  getListSlots,
  getUserInSlot,
  quickCreateSlot,
  uploauploadExpirationdSlot,
} from "../../../api/slotApi";
import { useModalProps } from "../../../hooks/useModalProps";
import useViewport from "../../../hooks/useViewport";
import AddSlotModal from "./components/AddSlotModal";
import QuickUpdateModal from "./components/QuickUpdateModal";
import SmartTable from "../../../components/SmartTable";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { usePermissionValidate } from "../../../hooks/usePermissionValidate";

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;

const SlotPage = () => {
  const { isWrite } = usePermissionValidate("f_all");
  const location = useLocation();
  const navigate = useNavigate();
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 958;
  const addSlotModal = useModalProps();
  const qiickUpdateModal = useModalProps();
  const { t }: any = useTranslation();

  const [listSlotRow, setListSlotRow] = useState([]);
  const [listSlotRowSearch, setListSlotRowSearch] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleGetAllSlot = async () => {
    setLoading(true);
    const data: any = await getListSlots("mode");
    if (data.code === 200 && data.data?.length) {
      const responseList = data.data?.map(async (slot: any) => {
        // const userUsed = await getUserInSlot(slot.id);
        return {
          key: slot._id,
          createdBy: slot.createdBy.fullName,
          createdAt: moment(slot.createdAt).format("DD/MM/YYYY"),
          code: slot.slotCode,
          maxUsage: slot.maxUsage,
          usageCount: slot.usageCount,
          userUsed: slot.email,
          expiredAt: moment(slot.expiredAt).format("DD/MM/YYYY"),
        };
      });
      const dataFormat: any = await Promise.all(responseList);
      setListSlotRow(dataFormat);
      setListSlotRowSearch(dataFormat);
      setLoading(false);
    } else {
      setListSlotRow([]);
      setListSlotRowSearch([]);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleCreateSlot = async (params: any) => {
    const res: any = await quickCreateSlot(params);
    if (res) {
      const filename = `new_slot_${params.expiredAt}.xlsx`; // Tên tệp mặc định
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      await handleGetAllSlot();
      return true;
    } else {
      return false;
    }
  };

  const handleSearch = (e: any) => {
    const newList = listSlotRow.filter((user: any) =>
      user.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setListSlotRowSearch(newList);
  };

  const handleCopyLinkRegister = (code: string) => {
    const fullURL = `${window.location.protocol}//${
      window.location.host
    }${`/auth/sign-up?slotCode=${code}`}`;
    navigator.clipboard
      .writeText(fullURL)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDownloadAllSlot = async () => {
    const res: any = await downloadAllSlot();
    console.log(res);
    if (res) {
      const filename = `all_slot_${moment().format("DD-MM-YYY")}.xlsx`; // Tên tệp mặc định
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  };

  const props: UploadProps = {
    name: "file",
    showUploadList: false,
    customRequest: async ({ onSuccess, onError, file }: any) => {
      const formData = new FormData();
      formData.append("file", file);
      const res: any = await uploauploadExpirationdSlot(formData);
      if (res.code === 200) {
        toast.success(`${file.name} file uploaded successfully.`);
      } else {
        toast.error(`Upload file failed.`);
      }
      onSuccess();
    },
  };

  const handleUploadSlotFile = () => {};

  const columns: any = [
    {
      dataIndex: "code",
      title: "Code",
      width: 150,
    },
    {
      dataIndex: "maxUsage_usageCount",
      title: "Usage Count/Max Usage",
      render: (data: any, row: any) => (
        <Flex align="center" justify="left" gap={5}>
          <PeopleAltOutlinedIcon />
          {row.usageCount}/{row.maxUsage}
        </Flex>
      ),
    },

    {
      dataIndex: "createdBy",
      title: "Created By",
      width: 200,
      renderCell: (data: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Avatar>{data.value[0]}</Avatar>
          {data.value}
        </div>
      ),
    },
    {
      dataIndex: "userUsed",
      title: "User Used Code",
      render: (data: any) => {
        return <Typography>{data}</Typography>;
      },
    },
    {
      dataIndex: "expiredAt",
      title: "Expired Date",
      width: 150,
    },
    {
      dataIndex: "operation",
      title: "Operation",
      render: (data: any, row: any) => {
        return (
          <div style={{ display: "flex", gap: 10 }}>
            <ContentCopyOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
              onClick={() => {
                handleCopyLinkRegister(row.code);
              }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetAllSlot();
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Grid
          style={{
            marginBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!isMobile && (
            <Grid item style={{ width: "55%" }}>
              <Search>
                <Input
                  placeholder={t("Search")}
                  onChange={(e) => handleSearch(e)}
                />
              </Search>
            </Grid>
          )}
          {!isMobile && (
            <Grid
              item
              style={{
                width: isMobile ? "100%" : "40%",
                display: "flex",
                justifyContent: "flex-start",
                gap: 10,
              }}
            >
              <Button
                variant="contained"
                onClick={addSlotModal.onOpen}
                style={{ borderRadius: 16, height: 45 }}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add New Slot
              </Button>

              <Button
                variant="contained"
                onClick={handleDownloadAllSlot}
                style={{ borderRadius: 16, height: 45 }}
                startIcon={<PublishOutlinedIcon />}
              >
                Export All
              </Button>
              <Upload {...props}>
                <Button
                  variant="contained"
                  onClick={handleUploadSlotFile}
                  style={{ borderRadius: 16, height: 45 }}
                  startIcon={<DriveFolderUploadOutlinedIcon />}
                >
                  Upload
                </Button>
              </Upload>
            </Grid>
          )}
        </Grid>
        {isMobile ? (
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            {/* {listSlotRowSearch.map((items: any) => (
              <SlotItem items={items} formEditUser={formEditUser} />
            ))} */}
            <h3 style={{ textAlign: "center" }}>Non support for mobile</h3>
          </div>
        ) : (
          <Table
            rowKey={"key"}
            loading={loading}
            dataSource={listSlotRowSearch}
            columns={columns}
            rowSelection={rowSelection}
            bordered
            scroll={{ y: "65vh" }}
          />
        )}
      </div>
      <AddSlotModal
        addSlotModal={addSlotModal}
        handleCreateSlot={handleCreateSlot}
      />
      <QuickUpdateModal qiickUpdateModal={qiickUpdateModal} />
    </>
  );
};

const SlotItem = ({}: any) => {
  return <>Non support for mobile</>;
};

export default SlotPage;
