import axiosClient from "./axiosClient";

export const getListDocumentActive = (cursor = "", keyword = "") => {
  const url = `/p/document?status=active&cursor=${cursor}&keyword=${keyword}`;
  return axiosClient.get(url);
};
export const getListDocumentTrash = (cursor = "", keyword = "") => {
  const url = `/p/document?status=trash&cursor=${cursor}&keyword=${keyword}`;
  return axiosClient.get(url);
};

export const getDocument = (id) => {
  const url = `/p/document/${id}`;
  return axiosClient.get(url);
};

export const createDocument = (params) => {
  const url = `/p/document`;
  return axiosClient.post(url, params);
};

export const updateDocument = (params, id) => {
  const url = `/p/document/${id}`;
  return axiosClient.put(url, params);
};

export const shareAllDocument = (id) => {
  const url = `/p/document/${id}/share`;
  return axiosClient.put(url, {
    target: "user",
    targetIDs: [],
    shareAll: true,
    permission: "pg__reader",
  });
};

export const blockShareAllDocument = (id) => {
  const url = `/p/document/${id}/share`;
  return axiosClient.put(url, {
    target: "user",
    targetIDs: [],
    shareAll: true,
    permission: "none",
  });
};

export const shareDocumentToUser = (id, params) => {
  const url = `/p/document/${id}/share`;
  return axiosClient.put(url, params);
};
export const peopleAccess = (id) => {
  const url = `/p/document/${id}/peopleAccess`;
  return axiosClient.get(url);
};
export const generalAccess = (id) => {
  const url = `/p/document/${id}/generalAccess`;
  return axiosClient.get(url);
};

export const deleteDocument = (idList) => {
  const url = `p/document`;
  const params = {
    documentIds: idList,
    status: "trash",
  };
  return axiosClient.patch(url, params);
};
export const revertDocument = (idList) => {
  const url = `p/document`;
  const params = {
    documentIds: idList,
    status: "active",
  };
  return axiosClient.patch(url, params);
};
