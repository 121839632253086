import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { Form, Input, Modal, Tree, TreeProps } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRoleById } from "../../../../api/roleApi";
import { MODAL_TYPE } from "../../../../constants/modalType";

const CreateRoleModal = ({ createRoleModal, handleCreateRole }: any) => {
  const permissionList = useSelector((state: any) => state.permissionList);
  const [form] = Form.useForm();

  const [defaultChecked, setDefaultChecked] = useState<any>([]);
  const [renderTree, setRenderTree] = useState<any>(true);

  const onCheck: TreeProps["onCheck"] = (checkedKeysValue) => {
    form.setFieldValue("permissions", checkedKeysValue);
    setDefaultChecked(checkedKeysValue);
  };

  const onFinish = async (values: any) => {
    if (!handleCreateRole) return;
    const res: any = await handleCreateRole(
      values,
      createRoleModal.data.type === MODAL_TYPE.EDIT
        ? MODAL_TYPE.EDIT
        : MODAL_TYPE.CREATE,
      createRoleModal.data?.data?.id
    );
    if (res === true) {
      createRoleModal.onCancel();
      form.resetFields();
      setDefaultChecked([]);
    }
  };

  const handleInitModal = async () => {
    if (createRoleModal.data) {
      const { type, data } = createRoleModal.data;
      if (type === MODAL_TYPE.EDIT && data !== null) {
        setRenderTree(false);
        const permissionUser: any = [];

        const res: any = await getRoleById(data.id);
        if (res.code === 200) {
          const permissionRole = res.data.permissions;
          const listPermissionFormat = Object.keys(permissionRole).forEach(
            async (key) => {
              // permissionUser.push(key);
              if (permissionRole[key].length > 0) {
                permissionRole[key].forEach(async (children: string) => {
                  permissionUser.push(key + "-" + children);
                });
              }
            }
          );
        }
        form.setFieldsValue({
          name: data.position,
          description: data.description,
          permissions: permissionUser,
        });
        setDefaultChecked(permissionUser);
        setRenderTree(true);
      }
    }
  };

  useEffect(() => {
    handleInitModal();
    return () => {
      form.resetFields();
      setDefaultChecked([]);
    };
  }, [createRoleModal.data]);

  return (
    <Modal
      open={createRoleModal.visible}
      onCancel={() => {
        createRoleModal.onCancel();
        form.resetFields();
      }}
      title={`${
        createRoleModal.data?.type === MODAL_TYPE.CREATE ? "Create New" : "Edit"
      } Role`}
      onOk={form.submit}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Name:"
          rules={[{ required: true, message: "Please input your role name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description:"
          rules={[
            { required: true, message: "Please input your role description!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="permissions"
          label="Permissions:"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (value !== "" && value !== undefined && value !== null) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Please select permission!");
                }
              },
            },
          ]}
        >
          {renderTree ? (
            <Tree
              multiple
              checkable
              onCheck={onCheck}
              treeData={permissionList}
              defaultCheckedKeys={[...defaultChecked]}
            />
          ) : (
            <RefreshOutlinedIcon />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateRoleModal;
