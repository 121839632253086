/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import { InboxOutlined } from "@mui/icons-material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Avatar,
  Button,
  Grid,
  InputBase,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing, width } from "@mui/system";
import {
  Checkbox,
  Form,
  Input as InputAnt,
  Modal,
  Popconfirm,
  Table,
  Upload,
  UploadProps,
} from "antd";
import moment from "moment";
import { darken } from "polished";
import React, { useEffect, useRef, useState } from "react";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  downloadMedia,
  getListMedia,
  revertMedia,
  updateMedia,
  uploadNewMediaFile,
} from "../../../../api/mediaApi";
import PdfIcon from "../../../../assets/image/pdf-icon.svg";
import { useModalProps } from "../../../../hooks/useModalProps";
import useViewport from "../../../../hooks/useViewport";
import _, { has } from "lodash";
import SmartTable from "../../../../components/SmartTable";
import { getUserWithId } from "../../../../api/userApi";
import {
  MEDIA_SUB_TYPE,
  MEDIA_SUB_TYPE_NAME,
  MEDIA_TYPE,
} from "../../../../constants/mediaType";

const Divider = styled(MuiDivider)(spacing);

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;

const { Dragger } = Upload;

const MediaTrash = () => {
  const { user } = useSelector((state: any) => state.user);
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 958;
  const { t }: any = useTranslation();
  const [listMedia, setListMedia] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const listRef = useRef<any>(null);

  const [hasMore, setHasMore] = useState(false);
  const [cursor, setCursor] = useState("");
  const [query, setQuery] = useState("");

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleGetListedia = async (cursorId = "", keyword = "") => {
    setLoading(true);
    const data: any = await getListMedia(cursorId, keyword);
    if (data?.code === 200 && data?.data !== null) {
      const activeData = data?.data;
      const dataFormat = activeData?.map(async (items: any) => {
        return {
          ...items,
          key: items.id,
          id: items.id,
          name: items.name,
          description: items.description,
          owner: items.owner.fullName,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
          size: "500KB",
          file: "pdf",
        };
      });
      const listData: any = await Promise.all(dataFormat);

      setHasMore(data?.meta.has_more ? data?.meta.has_more : false);
      if (data?.meta?.has_more) {
        setCursor(data?.meta?.cursor);
      } else {
        setCursor("");
      }
      setLoading(false);
      return listData;
    } else {
      setListMedia([]);
      setHasMore(false);
      setCursor("");
      setLoading(false);
      return [];
    }
  };

  const handleDownload = async (id: any, fileName: string) => {
    const file: any = await downloadMedia(id);
    if (file) {
      const blob = new Blob([file], { type: "application/pdf" });
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  };

  const handlePreview = async (item: any) => {
    if (
      item.type === MEDIA_TYPE.VIDEO &&
      item.subType === MEDIA_SUB_TYPE.YOUTUBE
    ) {
      const url = item.storageData?.link;
      window.open(url);
    } else {
      const file: any = await downloadMedia(item.id);
      if (file) {
        const blob = new Blob([file], {
          type: MEDIA_SUB_TYPE_NAME[item.subType],
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  };

  const columns: any = [
    {
      dataIndex: "name",
      title: "Name",
      width: "25%",
      render: (row: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <img src={PdfIcon} height={25} width={25} alt="" />
          {row}
        </div>
      ),
    },
    {
      dataIndex: "description",
      title: "Description",
      width: "30%",
    },
    {
      dataIndex: "owner",
      title: "Owner",
      width: "12.5%",
      render: (row: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Avatar>H</Avatar>
          {row}
        </div>
      ),
    },
    {
      dataIndex: "updated_at",
      title: "Last Modified",
      width: "12.5%",
    },
    {
      dataIndex: "operation",
      title: "Operation",
      width: "20%",
      render: (_: any, row: any) => {
        return (
          <div style={{ display: "flex", gap: 10 }}>
            {/* <EditOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
              onClick={() => {
                EditMediaModal.onOpen();
                form.setFieldsValue({
                  fileName: row.name,
                  description: row.description,
                  id: row.id,
                });
              }}
            /> */}
            <DownloadOutlinedIcon
              fontSize="medium"
              onClick={() => handleDownload(row.id, row.name)}
              className="cursor-pointer"
            />
            <VisibilityOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
              onClick={() => handlePreview(row)}
            />
            <Popconfirm
              placement="topLeft"
              title="Share"
              description="Are you sure you want to revert this file?"
              onConfirm={() => handleRevertMedia([row.id])}
            >
              <HistoryOutlinedIcon
                fontSize="medium"
                className="cursor-pointer"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleRevertMedia = async (idList: any) => {
    if (idList.length === 0) return;
    const res: any = await revertMedia(idList);
    if (res?.code === 200) {
      toast.success("Revert media success!");
      setSelectedRowKeys([]);
      const mediaList = await handleGetListedia();
      setListMedia(mediaList);
    } else {
      toast.warning("Server have problem, please try again later");
    }
  };

  const handleCheckMobile = (checked: any, id: any) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, id]);
    } else {
      const newList = selectedRowKeys.filter((val) => val !== id);
      setSelectedRowKeys(newList);
    }
  };

  const handleCheckAllMobile = () => {
    if (listMedia.length === selectedRowKeys.length) {
      setSelectedRowKeys([]);
    } else {
      const listKey = listMedia.map((items: any) => items.id);
      setSelectedRowKeys(listKey);
    }
  };

  const handleFetchMore = async () => {
    console.log(hasMore, cursor);
    if (hasMore) {
      const mediaList = await handleGetListedia(cursor);
      setListMedia((prev: any) => prev.concat(mediaList));
    }
  };

  const handleSearch = async (value: any) => {
    setQuery(value);
    if (value !== "") {
      const listData = await handleGetListedia("", value);
      setListMedia(listData);
    } else {
      const listData = await handleGetListedia();
      setListMedia(listData);
    }
  };

  const debouncedSearch = _.debounce(handleSearch, 500);

  const handleScrollMobile = async (e: any) => {
    if (
      listRef.current.clientHeight + listRef.current.scrollTop ===
        listRef.current.scrollHeight &&
      hasMore
    ) {
      await handleFetchMore();
    }
  };

  useEffect(() => {
    debouncedSearch(query);
    return () => {
      debouncedSearch.cancel();
    };
  }, [query]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.style.height = `${window.innerHeight - 300}px`;
    }
  }, [listRef.current]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.addEventListener("scroll", handleScrollMobile);
    }
    return () => {
      if (listRef.current !== null) {
        listRef.current.removeEventListener("scroll", handleScrollMobile);
      }
    };
  }, [listRef.current, hasMore]);

  return (
    <div>
      <Grid
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <Grid item style={{ width: "55%" }}>
            <Search>
              <Input
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t("Search")}
                value={query}
              />
            </Search>
          </Grid>
        )}
        <Grid
          item
          style={{
            width: isMobile ? "100%" : "40%",
            display: "flex",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <Popconfirm
            placement="topLeft"
            title="Share"
            description="Are you sure you want to revert all selected files?"
            onConfirm={() => handleRevertMedia(selectedRowKeys)}
          >
            <Button
              variant="contained"
              style={{ borderRadius: 16, height: 45 }}
              startIcon={<HistoryOutlinedIcon />}
            >
              Revert
            </Button>
          </Popconfirm>
        </Grid>
      </Grid>
      {isMobile ? (
        <>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <div>
              <Checkbox
                onChange={(e) => {
                  handleCheckAllMobile();
                }}
                checked={
                  selectedRowKeys.length === listMedia.length &&
                  listMedia.length > 0
                    ? true
                    : false
                }
              />
            </div>
            <div>|</div>
            <div>Name</div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              overflowY: "scroll",
            }}
            ref={listRef}
            // onScroll={handleScrollMobile}
          >
            {listMedia.map((items: any) => (
              <MediaItemMobile
                key={items.id}
                items={items}
                handleDownload={handleDownload}
                handlePreview={handlePreview}
                handleRevertMedia={handleRevertMedia}
                handleCheckMobile={handleCheckMobile}
                selectedRowKeys={selectedRowKeys}
              />
            ))}
          </div>
        </>
      ) : (
        <SmartTable
          dataSource={listMedia}
          columns={columns}
          loading={loading}
          rowSelection={rowSelection}
          bordered
          pagination={false}
          infinity={true}
          onFetch={handleFetchMore}
          lastId={listMedia[listMedia.length - 1]?.id}
          scroll={{ y: "60vh", x: "auto" }}
        />
      )}
    </div>
  );
};

const MediaItemMobile = ({
  items,
  form,
  handleDownload,
  handlePreview,
  handleRevertMedia,
  handleCheckMobile,
  selectedRowKeys,
}: any) => {
  const [isShowAction, setIsShowAction] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
          <Checkbox
            checked={selectedRowKeys.includes(items.id) ? true : false}
            onChange={(e) => {
              handleCheckMobile(e.target.checked, items.id);
            }}
          />
          <img src={PdfIcon} height={25} width={25} /> {items.name} - (
          {items.owner})
        </div>
        <div>
          <MoreHorizOutlinedIcon
            onClick={() => setIsShowAction(!isShowAction)}
          />
        </div>
      </div>
      {isShowAction && (
        <div style={{ display: "flex", gap: 10, justifyContent: "flex-end" }}>
          <DownloadOutlinedIcon
            fontSize="medium"
            onClick={() => handleDownload(items.id, items.name)}
            className="cursor-pointer"
          />
          <VisibilityOutlinedIcon
            fontSize="medium"
            className="cursor-pointer"
            onClick={() => handlePreview(items)}
          />
          <Popconfirm
            placement="topLeft"
            title="Share"
            description="Are you sure you want to revert this file?"
            onConfirm={() => handleRevertMedia([items.id])}
          >
            <HistoryOutlinedIcon fontSize="medium" className="cursor-pointer" />
          </Popconfirm>
        </div>
      )}
      <Divider />
    </>
  );
};

export default MediaTrash;
