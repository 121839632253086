import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import {
  Avatar,
  Button,
  Grid,
  InputBase,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Flex, Form, Popconfirm, Table } from "antd";
import { darken } from "polished";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createNewRole,
  deleteRoleById,
  getListRole,
  updateRoleById,
} from "../../../../api/roleApi";
import { MODAL_TYPE } from "../../../../constants/modalType";
import { useModalProps } from "../../../../hooks/useModalProps";
import useViewport from "../../../../hooks/useViewport";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import CreateRoleModal from "./CreateRoleModal";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { permission } from "../../../../redux/slices/permissionList";
import { useNavigate } from "react-router-dom";

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const RolesManage = () => {
  const { user } = useSelector((state: any) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 958;
  const createRoleModal = useModalProps();
  const { t }: any = useTranslation();
  const [listUserRow, setListUserRow] = useState([]);
  const [listUserRowSearch, setListUserRowSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    handleGetAlRole();
  }, []);

  const handleGetAlRole = async () => {
    setLoading(true);
    const { data } = await getListRole();

    let dataFormat = data.map(async (role: any) => {
      console.log(role);
      return {
        key: role.id,
        id: role.id,
        position: role.name,
        description: role.description,
        email: role.email,
        phone: role.phone,
        status: role.status,
        role: role.permissions,
      };
    });
    dataFormat = await Promise.all(dataFormat);
    setListUserRow(dataFormat);
    setListUserRowSearch(dataFormat);
    setLoading(false);
  };

  const handleCreateRole = async (
    values: any,
    type: string = MODAL_TYPE.CREATE,
    id: string | null = null
  ) => {
    const permissions: any = {};

    const asyncArray = values.permissions.map(async (pItem: string) => {
      if (pItem.includes("-p")) {
        const [key, value] = pItem.split("-");
        if (permissions[key]) {
          permissions[key].push(value);
        } else {
          permissions[key] = [value];
        }
      }
      return;
    });
    await Promise.all(asyncArray);

    const params = {
      name: values.name,
      description: values.description,
      permissions: permissions,
    };

    let res: any = null;
    if (type === MODAL_TYPE.CREATE) {
      res = await createNewRole(params);
    } else {
      res = await updateRoleById(id, params);
    }
    if (res.code === 200) {
      handleGetAlRole();
      toast.success(`${capitalizeFirstLetter(res.message)}`);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  };

  const handleSearch = (e: any) => {
    const newList = listUserRow.filter((user: any) =>
      user.position.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setListUserRowSearch(newList);
  };

  const handleDeleteRole = async (id: string) => {
    try {
      if (!id) return;
      const res: any = await deleteRoleById(id);
      if (res.code === 200) {
        handleGetAlRole();
        toast.success(`${capitalizeFirstLetter(res.message)}`);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetListPermission = async () => {
    const action = permission();
    const res: any = await dispatch(action);
    if (JSON.stringify(res) === "{}") {
      navigate("/auth/404");
    }
  };

  const columns: any = [
    {
      dataIndex: "position",
      title: "Position",
    },
    {
      dataIndex: "role",
      title: "Role",
      render: (data: any) => {
        return (
          <Flex vertical>
            {Object.keys(data).map((key: string, idx1: number) => {
              return (
                <Flex key={key + idx1} gap={5}>
                  {capitalizeFirstLetter(key.slice(3))} <div>-</div>
                  <Flex gap={5}>
                    (
                    {data[key].map((item: any, idx2: number) => (
                      <div key={key + idx1 + idx2}>
                        {capitalizeFirstLetter(item.slice(3))}
                        {idx2 !== data[key].length - 1 && "/"}
                      </div>
                    ))}
                    )
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        );
      },
    },
    {
      dataIndex: "description",
      title: "Description",
    },
    {
      dataIndex: "operation",
      title: "Operation",
      render: (data: any, row: any) => {
        return (
          <div style={{ display: "flex", gap: 10 }}>
            <EditOutlinedIcon
              fontSize="medium"
              onClick={() => {
                const params = {
                  type: MODAL_TYPE.EDIT,
                  data: row,
                };
                createRoleModal.onOpen(params);
              }}
              className="cursor-pointer"
            />
            <Popconfirm
              placement="topLeft"
              title="Delete"
              description={`Are you sure to delete role?`}
              className="cursor-pointer"
              onConfirm={() => {
                handleDeleteRole(row.id);
              }}
            >
              <DeleteOutlineOutlinedIcon fontSize="medium" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useLayoutEffect(() => {
    handleGetListPermission();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Grid
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <Grid item style={{ width: "55%" }}>
            <Search>
              <Input
                placeholder={t("Search")}
                onChange={(e) => handleSearch(e)}
              />
            </Search>
          </Grid>
        )}
        <Grid
          item
          style={{
            width: isMobile ? "100%" : "40%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            onClick={createRoleModal.onOpen}
            style={{ borderRadius: 16, height: 45 }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add New Position
          </Button>
        </Grid>
      </Grid>
      {isMobile ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          {listUserRowSearch.map((items: any, idx: number) => (
            <UserItem
              key={idx}
              items={items}
              createRoleModal={createRoleModal}
              handleDeleteRole={handleDeleteRole}
            />
          ))}
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={listUserRowSearch}
          columns={columns}
          rowSelection={rowSelection}
          bordered
        />
      )}
      <CreateRoleModal
        createRoleModal={createRoleModal}
        handleCreateRole={handleCreateRole}
      />
    </div>
  );
};

const UserItem = ({ items, createRoleModal, handleDeleteRole }: any) => {
  const [isShowAction, setIsShowAction] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          {items.position}
          <Flex vertical>
            {Object.keys(items.role).map((key: string, idx1: number) => {
              return (
                <Flex key={key + idx1} gap={5}>
                  {capitalizeFirstLetter(key.slice(3))} <div>-</div>
                  <Flex gap={5}>
                    (
                    {items.role[key].map((item: any, idx2: number) => (
                      <div key={key + idx1 + idx2}>
                        {capitalizeFirstLetter(item.slice(3))}
                        {idx2 !== items.role[key].length - 1 && "/"}
                      </div>
                    ))}
                    )
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </div>
        <div>
          <MoreHorizOutlinedIcon
            onClick={() => setIsShowAction(!isShowAction)}
          />
        </div>
      </div>
      {isShowAction && (
        <div style={{ display: "flex", gap: 10 }}>
          <EditOutlinedIcon
            fontSize="medium"
            onClick={() => {
              const params = {
                type: MODAL_TYPE.EDIT,
                data: items,
              };
              createRoleModal.onOpen(params);
            }}
            className="cursor-pointer"
          />
          <Popconfirm
            placement="topLeft"
            title="Delete"
            description={`Are you sure to delete role?`}
            className="cursor-pointer"
            onConfirm={() => {
              handleDeleteRole(items.id);
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="medium" />
          </Popconfirm>
        </div>
      )}
      <Divider />
    </>
  );
};

export default RolesManage;
