import { useCallback, useState } from "react";

export const useModalProps = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);

  const onOpen = useCallback((values: any = null) => {
    setVisible(true);
    setData(values);
  }, []);

  const onCancel = useCallback(() => setVisible(false), []);

  return {
    visible,
    onOpen,
    onCancel,
    data,
  };
};
