import styled from "@emotion/styled";
import { Avatar, Flex, Form, Modal, Select, Space, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { peopleAccess } from "../../../../api/documentApi";
import { getAllUser } from "../../../../api/userApi";
import "./Component.scss";
import { MODAL_TYPE } from "../../../../constants/modalType";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { UserOutlined } from "@ant-design/icons";

const AddUserToDocumentModal = ({ addUserModal, handleShareUser }: any) => {
  const [form] = Form.useForm();
  const [listUser, setListUser] = useState<any>([]);
  const [listUserAccessId, setListUserAccessId] = useState([]);
  const [listUserAvailable, setListUserAvailable] = useState<any>([]);

  const handleGetUserAccess = async () => {
    if (!addUserModal.data?.id) return;
    const res: any = await peopleAccess(addUserModal.data?.id);
    if (res?.code === 200 && res?.data?.length > 0) {
      setListUserAvailable(res?.data);
      console.log("res?.data", res?.data);
      return res?.data;
    } else {
      return [];
    }
  };

  const handleGetListUserAdd = async () => {
    const data: any = await getAllUser();
    if (data.code === 200 && data.data !== null) {
      const listUserAccess = await handleGetUserAccess();
      const listUserValues = listUserAccess?.map((user: any) => {
        return user?.sharedUser?.id;
      });
      // setListUserAccessId(listUserValues);
      const dataFormat = data?.data.map(async (items: any) => {
        return {
          value: items.id,
          label: items.fullName,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
          disabled: listUserValues.includes(items.id),
        };
      });
      const listData: any = await Promise.all(dataFormat);
      setListUser(listData);
    } else {
      setListUser([]);
    }
  };

  const handleGetListUserRemove = async () => {
    const listUserAccess = await handleGetUserAccess();
    const listUserValues = listUserAccess?.map((user: any) => {
      return user.sharedUser.id;
    });
    form.setFieldValue("targetIDs", listUserValues);
    setListUserAccessId(listUserValues);

    const listUserOptions = listUserAccess?.map((info: any) => {
      const { sharedUser } = info;
      return {
        value: sharedUser.id,
        label: sharedUser.fullName,
      };
    });
    setListUser(listUserOptions);
  };

  const onFinish = async (values: any) => {
    let targetIDs = values.targetIDs || [];
    if (addUserModal.data?.type === MODAL_TYPE.REMOVE) {
      targetIDs = listUserAccessId.filter((user: any) => {
        return !targetIDs.includes(user);
      });
      if (targetIDs.length === 0) {
        toast.warning("You must remove at least one user in the group");
        return;
      }
    }

    const params = {
      target: "user",
      targetIDs: targetIDs,
      shareAll: false,
      permission:
        addUserModal.data?.type === MODAL_TYPE.ADD ? "pg__reader" : "none",
    };
    const res: any = await handleShareUser(params, addUserModal.data?.id);
    if (res === true) {
      addUserModal.onCancel();
      toast.success(
        `${
          addUserModal.data?.type === MODAL_TYPE.ADD
            ? "Add user"
            : "Remove User"
        } success!`
      );
      form.resetFields();
    } else {
      toast.warning(`${capitalizeFirstLetter(res)}`);
    }
  };

  useEffect(() => {
    if (addUserModal.data?.type === MODAL_TYPE.ADD) {
      handleGetListUserAdd();
    } else {
      handleGetListUserRemove();
    }

    return () => {
      form.resetFields();
      setListUser([]);
      setListUserAvailable([]);
    };
  }, [addUserModal.visible, addUserModal.data?.type]);

  return (
    <Modal
      open={addUserModal.visible}
      onCancel={() => {
        addUserModal.onCancel();
        form.resetFields();
      }}
      className="create-document-modal"
      onOk={form.submit}
      title={"Edit user"}
    >
      <Form form={form} onFinish={onFinish} className="document-form">
        <div className="create-document-wrap">
          <Form.Item name="targetIDs">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select user"
              optionLabelProp="label"
              options={listUser}
              tagRender={() => <></>}
              optionRender={(option) => <Space>{option.data.label}</Space>}
            />
          </Form.Item>
        </div>
      </Form>
      {listUserAvailable.length > 0 && (
        <Flex vertical gap={5} style={{ padding: 10 }}>
          <Typography.Text strong>Users in document:</Typography.Text>
          <Flex vertical gap={5} style={{ overflow: "scroll", maxHeight: 300 }}>
            {listUserAvailable.map((user: any) => (
              <div key={user.sharedUser.id}>
                <Flex align="center" gap={5}>
                  <Avatar size={40}>
                    {user.sharedUser.fullName[0] ? (
                      user.sharedUser.fullName[0].toUpperCase()
                    ) : (
                      <UserOutlined />
                    )}
                  </Avatar>
                  <Flex vertical>
                    {user.fullName}
                    <span style={{ color: "#000" }}>
                      {user.sharedUser.fullName}
                    </span>
                  </Flex>
                </Flex>
              </div>
            ))}
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default AddUserToDocumentModal;
