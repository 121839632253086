import { Form, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getListRole } from "../../../../api/roleApi";

const CreateUserModal = ({ createUserModal, handleCreateUser }: any) => {
  const [form] = Form.useForm();
  const [listRole, setListRole] = useState([]);

  const handleGetListRole = async () => {
    const res: any = await getListRole();
    if (res.code === 200) {
      const roles = res?.data?.map((role: any) => {
        return {
          value: role?.id,
          label: role?.name,
        };
      });
      setListRole(roles);
    } else {
      toast.warning("Server have a problem, please try again later!");
    }
  };

  const onFinish = async (values: any) => {
    const res: any = await handleCreateUser(values);
    if (res === true) {
      form.resetFields();
    }
  };

  useEffect(() => {
    handleGetListRole();
    return () => {
      form.resetFields();
    };
  }, [createUserModal.visible]);

  return (
    <Modal
      open={createUserModal.visible}
      onCancel={() => {
        createUserModal.onCancel();
        form.resetFields();
      }}
      title="Create New User"
      onOk={form.submit}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="fullName"
          label="Full name:"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email:"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password:"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        {/* <Form.Item
          name="phone"
          label="Phone:"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <InputNumber controls={false} style={{ width: "100%" }} />
        </Form.Item> */}
        <Form.Item
          name="roles"
          label="Role:"
          rules={[{ required: true, message: "Please select role!" }]}
        >
          <Select
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            // filterSort={(optionA, optionB: any) =>
            //   (optionA?.label ?? "")
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? "").toLowerCase())
            // }
            options={listRole}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
