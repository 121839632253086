import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import {
  Avatar,
  Button,
  Grid,
  InputBase,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Form, Popconfirm, Table } from "antd";
import { darken } from "polished";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createUser,
  getAllUser,
  getUserRoleWithId,
  updateStatusUser,
  updateUserInfo,
  updateUserRole,
} from "../../../../api/userApi";
import { useModalProps } from "../../../../hooks/useModalProps";
import useViewport from "../../../../hooks/useViewport";
import CreateUserModal from "./CreateUserModal";
import EditRoleUserModal from "./EditRoleUserModal";
import EditUserModal from "./EditUserModal";
import { assignRoles } from "../../../../api/roleApi";

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const UserManage = () => {
  const { user } = useSelector((state: any) => state.user);
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 958;
  const createUserModal = useModalProps();
  const editUserModal = useModalProps();
  const editRoleUserModal = useModalProps();
  const { t }: any = useTranslation();
  const [listUserRow, setListUserRow] = useState([]);
  const [listUserRowSearch, setListUserRowSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    handleGetAllUser();
  }, []);

  const handleGetAllUser = async () => {
    setLoading(true);
    const { data } = await getAllUser();
    let dataFormat = data.map(async (user: any) => {
      const userRole: any = await getUserRoleWithId(user.id);
      return {
        key: user.id,
        id: user.id,
        name: user.fullName,
        email: user.email,
        phone: user.phone,
        status: user.status,
        role: userRole.code === 200 ? userRole.data[0].name : "",
        roleId: userRole.code === 200 ? userRole.data[0].id : "",
      };
    });
    dataFormat = await Promise.all(dataFormat);
    setListUserRow(dataFormat.reverse());
    setListUserRowSearch(dataFormat);
    setLoading(false);
  };

  const handleOpenModalAddUser = () => {
    createUserModal.onOpen();
  };

  const handleCreateUser = async (values: any) => {
    const params = {
      fullName: values.fullName,
      email: values.email,
      password: values.password,
      roles: [values.roles],
    };
    const res: any = await createUser(params);
    if (res.code === 200) {
      handleGetAllUser();
      toast.success("Create new user success!");
      createUserModal.onCancel();
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  };

  const handleSearch = (e: any) => {
    const newList = listUserRow.filter((user: any) =>
      user.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setListUserRowSearch(newList);
  };

  const handleUpdateUserInfo = async (data: any) => {
    const params = {
      userIds: [data.id.toString()],
      status: data.status.toLowerCase() === "active" ? "Deactivate" : "Active",
    };
    const res: any = await updateStatusUser(params);
    if (res.code === 200) {
      toast.success(
        `Update status to ${
          data.status.toLowerCase() === "active" ? "Deactivate" : "Active"
        } successfully`
      );
      handleGetAllUser();
    }
  };

  const handleEditUser = async (params: any, id: string) => {
    const res: any = await updateUserInfo(id, params);
    if (res.code === 200) {
      toast.success("Update user info was successful");
      handleGetAllUser();
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  };

  const handleUpdateUserRole = async (
    userId: string,
    newRoleId: string,
    oldRoleId: string
  ) => {
    const params = {
      assignedRoles: [newRoleId],
      unassignedRoles: [oldRoleId],
    };
    const res: any = await assignRoles(userId, params);
    if (res.code === 200) {
      toast.success("Update user role was successful");
      handleGetAllUser();
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  };

  const columns: any = [
    {
      dataIndex: "name",
      title: "Name",
      width: 300,
      renderCell: (row: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Avatar>{row.value[0]}</Avatar>
          {row.value}
        </div>
      ),
    },
    {
      dataIndex: "email",
      title: "Email",
      width: 150,
    },
    {
      dataIndex: "role",
      title: "Role",
      width: 150,
    },
    {
      dataIndex: "status",
      title: "Status",
      width: 150,
    },
    {
      dataIndex: "operation",
      title: "Operation",
      render: (data: any, row: any) => {
        return (
          <div style={{ display: "flex", gap: 10 }}>
            <EditOutlinedIcon
              fontSize="medium"
              onClick={() => {
                editUserModal.onOpen({
                  fullName: row.name,
                  email: row.email,
                  status: "Active",
                  password: "",
                  id: row.id,
                });
              }}
              className="cursor-pointer"
            />
            <ManageAccountsOutlinedIcon
              fontSize="medium"
              onClick={() => {
                editRoleUserModal.onOpen({
                  id: row.id,
                  roleName: row.role,
                  roleID: row.roleId,
                });
              }}
              className="cursor-pointer"
            />
            <Popconfirm
              placement="topLeft"
              title="Share"
              description={`Are you sure to ${
                row.status === "Active" ? "Deactivate" : "Active"
              }?`}
              onConfirm={() => handleUpdateUserInfo(row)}
            >
              {row.status === "Active" ? (
                <DeleteOutlineOutlinedIcon fontSize="medium" />
              ) : (
                <HistoryOutlinedIcon fontSize="medium" />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Grid
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <Grid item style={{ width: "55%" }}>
            <Search>
              <Input
                placeholder={t("Search")}
                onChange={(e) => handleSearch(e)}
              />
            </Search>
          </Grid>
        )}
        <Grid
          item
          style={{
            width: isMobile ? "100%" : "40%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpenModalAddUser}
            style={{ borderRadius: 16, height: 45 }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add New User
          </Button>
        </Grid>
      </Grid>
      {isMobile ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          {listUserRowSearch.map((items: any) => (
            <UserItem
              key={items.id ? items.id : Math.random()}
              items={items}
              editUserModal={editUserModal}
              handleUpdateUserInfo={handleUpdateUserInfo}
              editRoleUserModal={editRoleUserModal}
            />
          ))}
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={listUserRowSearch}
          columns={columns}
          rowSelection={rowSelection}
          bordered
        />
      )}
      <CreateUserModal
        createUserModal={createUserModal}
        handleCreateUser={handleCreateUser}
      />
      <EditUserModal
        editUserModal={editUserModal}
        handleEditUser={handleEditUser}
      />
      <EditRoleUserModal
        editRoleUserModal={editRoleUserModal}
        handleUpdateUserRole={handleUpdateUserRole}
      />
    </div>
  );
};

const UserItem = ({
  items,
  editUserModal,
  handleUpdateUserInfo,
  editRoleUserModal,
}: any) => {
  const [isShowAction, setIsShowAction] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          {items.name} ({items.email})
        </div>
        <div>
          <MoreHorizOutlinedIcon
            onClick={() => setIsShowAction(!isShowAction)}
          />
        </div>
      </div>
      {isShowAction && (
        <div
          style={{
            display: "flex",
            gap: 10,
            cursor: "pointer",
            justifyContent: "flex-end",
          }}
        >
          <EditOutlinedIcon
            fontSize="medium"
            onClick={() => {
              editUserModal.onOpen({
                fullName: items.name,
                email: items.email,
                status: "Active",
                password: "",
                id: items.id,
              });
            }}
            className="cursor-pointer"
          />
          <ManageAccountsOutlinedIcon
            fontSize="medium"
            onClick={() => {
              editRoleUserModal.onOpen({ id: items.id, roleName: items.role });
            }}
            className="cursor-pointer"
          />
          <Popconfirm
            placement="topLeft"
            title="Share"
            description={`Are you sure to ${
              items.status === "Active" ? "Deactivate" : "Active"
            }?`}
            onConfirm={() => handleUpdateUserInfo(items)}
          >
            {items.status === "Active" ? (
              <DeleteOutlineOutlinedIcon fontSize="medium" />
            ) : (
              <HistoryOutlinedIcon fontSize="medium" />
            )}
          </Popconfirm>
        </div>
      )}
      <Divider />
    </>
  );
};

export default UserManage;
