import React from "react";

const TermsOfService = () => {
  return (
    <div>
      <h1>Điều Khoản Dịch Vụ</h1>
      <h2>1. Chấp Nhận Điều Khoản</h2>
      <p>
        Khi bạn sử dụng dịch vụ của chúng tôi, bạn đồng ý tuân thủ các điều
        khoản và điều kiện này.
      </p>

      <h2>2. Thay Đổi Điều Khoản</h2>
      <p>
        Chúng tôi có quyền thay đổi các điều khoản này bất cứ lúc nào. Những
        thay đổi sẽ có hiệu lực ngay khi được đăng tải trên trang web. Việc bạn
        tiếp tục sử dụng dịch vụ sau khi có các thay đổi đó có nghĩa là bạn chấp
        nhận các thay đổi.
      </p>

      <h2>3. Sử Dụng Dịch Vụ</h2>
      <p>
        Bạn cam kết sử dụng dịch vụ một cách hợp pháp và không vi phạm quyền của
        bất kỳ bên thứ ba nào. Bạn không được sử dụng dịch vụ để phát tán nội
        dung xâm phạm, gây hại, hoặc bất hợp pháp.
      </p>

      <h2>4. Quyền Sở Hữu Trí Tuệ</h2>
      <p>
        Tất cả các quyền sở hữu trí tuệ liên quan đến dịch vụ (bao gồm nhưng
        không giới hạn các văn bản, hình ảnh, logo, và mã nguồn) thuộc về chúng
        tôi hoặc các bên thứ ba cấp phép cho chúng tôi.
      </p>

      <h2>5. Giới Hạn Trách Nhiệm</h2>
      <p>
        Chúng tôi không chịu trách nhiệm về bất kỳ thiệt hại nào phát sinh từ
        việc sử dụng dịch vụ của bạn, trừ khi những thiệt hại đó do lỗi cố ý
        hoặc sơ suất nghiêm trọng của chúng tôi.
      </p>

      <h2>6. Chấm Dứt Dịch Vụ</h2>
      <p>
        Chúng tôi có quyền chấm dứt hoặc tạm ngừng cung cấp dịch vụ cho bạn nếu
        bạn vi phạm bất kỳ điều khoản nào trong các điều khoản này.
      </p>

      <h2>Liên Hệ</h2>
      <p>
        Nếu bạn có bất kỳ câu hỏi nào về điều khoản dịch vụ này, xin vui lòng
        liên hệ với chúng tôi qua email: support@example.com
      </p>
    </div>
  );
};

export default TermsOfService;
