import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { Flex } from "antd";
import GoogleButton from "react-google-button";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import SignInComponent from "../../components/auth/SignIn";
import { REACT_APP_BASE_URL } from "../../constants";
import { loginSocial } from "../../redux/slices/user";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import PageLoading from "../components/PageLoading";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 180px;
  height: 60px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const dispatchAction = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { loading } = useSelector((state: any) => state.user);

  const handleLoginGoogle = async () => {
    try {
      const url = `${REACT_APP_BASE_URL}/o/authorize/google?callback=${`${window.location.protocol}//${window.location.host}/auth/sign-in`}`;
      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoginSocial = async (user: any) => {
    const action = loginSocial(user);
    const res = await dispatchAction(action);
    if (res.payload.code === 200) {
      navigate("/");
    }
  };

  useEffect(() => {
    const token: string | null = window.localStorage.getItem("accessToken");
    if (token !== null) {
      navigate("");
    } else {
      const accessToken: any = searchParams.get("accessToken");
      const fullName: any = searchParams.get("fullName");
      const oauth2: any = searchParams.get("oauth2");
      const message: any = searchParams.get("message");
      if (!!accessToken && !!fullName && oauth2 === "true") {
        const user = {
          accessToken,
          fullName,
        };
        handleLoginSocial(user);
      } else {
        toast.error(message);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <PageLoading loading={loading ? loading : false} />
      <Brand />
      <Wrapper>
        <Helmet title="Sign In" />
        {/* <BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" /> */}

        {/* <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome back, Lucy!
        </Typography> */}
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
        <Flex align="center" justify="center" style={{ marginTop: 20 }}>
          <GoogleButton onClick={handleLoginGoogle} />
        </Flex>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
