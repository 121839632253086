import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import NavbarUserDropdown from "./NavbarUserDropdown";

const AppBar = styled(MuiAppBar)`
  // background: ${(props) => props.theme.header.background};
  background: #fafaf9;
  color: ${(props) => props.theme.header.color};
  height: 64px;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 120px;
  height: 60px;

  vertical-align: middle;
  display: inline;
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid
          container
          alignItems="center"
          sx={{ justifyContent: { xs: "space-between", md: "flex-end" } }}
        >
          <Grid item sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <NavbarUserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withTheme(Navbar);
