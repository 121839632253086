import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import SignUpComponent from "../../components/auth/SignUp";
import { isValidToken } from "../../utils/jwt";
import { ReactComponent as Logo } from "../../vendor/logo.svg";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 180px;
  height: 60px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken)) {
      navigate("/");
    }
  }, []);

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Get started
        </Typography>

        <SignUpComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
