import { DatePicker, Form, Input, InputNumber, Modal } from "antd";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";

const AddSlotModal = ({ addSlotModal, handleCreateSlot }: any) => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    if (!handleCreateSlot) return;
    const params = {
      totalSlot: values.totalSlot,
      expiredAt: moment(new Date(values.expiredAt)).format("YYYY-MM-DD"),
    };
    const res = await handleCreateSlot(params);
    if (res === true) {
      toast.success("Create slot success");
    } else {
      toast.error("Create slot failed");
    }
    form.resetFields();
    addSlotModal.onCancel();
  };
  return (
    <Modal
      open={addSlotModal.visible}
      onCancel={addSlotModal.onCancel}
      onOk={form.submit}
      className="user-modal"
      title={"Add Slot"}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="totalSlot"
          label="Total Slot:"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="expiredAt"
          label="Expired At:"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <DatePicker
            disabledDate={(current: any) =>
              current && current.valueOf() < Date.now()
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSlotModal;
