import { Form, Input, Modal } from "antd";
import React from "react";
import { updateMyPassword } from "../../../api/userApi";
import { toast } from "react-toastify";

const UpdatePassWordModal = ({ modalProps }: any) => {
  const [form] = Form.useForm();

  const handleSubmitForm = async (values: any) => {
    try {
      const params = {
        old_password: values.old_password,
        new_password: values.new_password,
        renew_password: values.renew_password,
      };
      const res: any = await updateMyPassword(params);
      if (res.code === 200) {
        toast.success("Update password successfully!");
        modalProps.onCancel();
        form.resetFields();
      } else {
        toast.warning(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={modalProps.visible}
      onCancel={() => {
        modalProps.onCancel();
        form.resetFields();
      }}
      title="Update Password"
      onOk={form.submit}
    >
      <Form form={form} onFinish={handleSubmitForm}>
        <Form.Item label="Old Password:" name="old_password">
          <Input type="password" />
        </Form.Item>
        <Form.Item label="New Password:" name="new_password">
          <Input type="password" />
        </Form.Item>
        <Form.Item label="Renew Password:" name="renew_password">
          <Input type="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePassWordModal;
