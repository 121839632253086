import axiosClient from "./axiosClient";

export const getListChat = (id) => {
  const url = `/p/chat/mine`;
  return axiosClient.get(url);
};

export const getByChatId = (id) => {
  const url = `/p/chat/${id}`;
  return axiosClient.get(url);
};
export const updateChatTitle = (id, params) => {
  const url = `/p/chat/${id}`;
  return axiosClient.put(url, params);
};
export const deleteChat = (id) => {
  const url = `/p/chat/${id}`;
  return axiosClient.delete(url);
};

export const getMessageByChatId = (id, cursor) => {
  const url = `/p/chat/${id}/messages?cursor=${cursor}`;
  return axiosClient.get(url);
};
