import React, { useEffect } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import RolesManage from "./components/RolesManage";
import UserManage from "./components/UserManage";
import TeamManage from "./components/TeamManage";
import { useLocation, useNavigate } from "react-router-dom";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Users Management",
    children: <UserManage />,
  },
  {
    key: "2",
    label: "Role Management",
    children: <RolesManage />,
  },
  {
    key: "3",
    label: "Teams Management",
    children: <TeamManage />,
  },
];

const UsersPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/user-list") {
      navigate("/user-list");
    }
  }, []);

  const onChange = (key: string) => {};
  return (
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} size="large" />
  );
};

export default UsersPage;
